import { useState } from "react";
import ReactiveButton from "reactive-button";

const SendButton = ({ name, onClick }) => {
  const [state, setState] = useState("idle");

  const onClickHandler = () => {
    setState("loading");
    setTimeout(() => {
      setState("success");
    }, 2000);
  };
  return (
    <ReactiveButton
      color={"#2ca65d"}
      buttonState={state}
      idleText={name}
      loadingText="Sending.."
      successText="Done"
      onClick={() => {
        onClickHandler();
        onClick();
      }}
      rounded={true}
      style={{
        backgroundColor: "var(--primary_button_color)",
        color: "var(--button_text_color)",
        fontFamily: "var(--hot_action_requests_send_request_button)",
      }}
    />
  );
};

export default SendButton;
