import React from "react";
import "../styles/orderSteper.scss";
import { MdOutlinePendingActions } from "react-icons/md";
import { ORDER_STATUS } from "../constants";
import { useTranslation } from "react-i18next";
import { BsFillBagCheckFill } from "react-icons/bs";
import { IoIosTimer } from "react-icons/io";

export default function OrderSteper({ status }) {
  const { t } = useTranslation();

  return (
    <div className="order-stepper">
      <div className={`${ORDER_STATUS[status] >= 0 ? "active" : ""}`}>
        <MdOutlinePendingActions className="icon" />
        <p>{t("cart.pending")}</p>
      </div>
      <div className={`line ${ORDER_STATUS[status] > 0 ? "activehr" : ""}`} />
      <div className={` ${ORDER_STATUS[status] > 0 ? "active" : ""}`}>
        {" "}
        <IoIosTimer className="icon" />
        <p>{t("cart.preparing")}</p>
      </div>
      <div className={`line ${ORDER_STATUS[status] > 1 ? "activehr" : ""}`} />
      <div className={`${ORDER_STATUS[status] > 1 ? "active" : ""}`}>
        {" "}
        <BsFillBagCheckFill className="icon" />
        <p>{t("cart.ready")}</p>
      </div>
    </div>
  );
}
