import { createSlice } from '../../Redux/toolkit';
import { useInjectReducer, useInjectSaga } from '../../Redux/redux-injectors';
import menuSaga from './saga';
// import moment from 'moment';

export const initialState = {
  loading: false,
  getFoodsInProgress: false,
  selectedStore: null,
  foodsAndCategories: null,
  selectedFood: null,
  categoryModifierIds: null,
  categoryTaxIds: null,
  modifiersDetails: null,
  taxesInDetails: null,
  storeValidated: false,
  recomendedFoods: [],
  promotions: [],
};

export const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    getStoreData(state, action) {
      state.loading = true;
    },
    getStoreDataSucceeded(state, action) {
      state.loading = false;
      state.selectedStore = action.payload;
    },
    getStoreDataFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getFoodsAndCategories(state, action) {
      state.getFoodsInProgress = true;
    },
    getFoodsAndCategoriesSucceeded(state, action) {
      state.getFoodsInProgress = false;
      state.foodsAndCategories = action.payload;
    },
    getFoodsAndCategoriesFailed(state, action) {
      state.getFoodsInProgress = false;
      state.errorMessage = action.payload;
    },
    setSelectedFood(state, action) {
      state.selectedFood = action.payload;
      if (action.payload) {
        state.categoryModifierIds = state.foodsAndCategories
          ?.map((item) => {
            if (item._id === state.selectedFood?.categoryId) {
              return item.category[0]?.modifierGroups;
            }
            return undefined;
          })
          .filter((item) => item !== undefined);
        state.categoryTaxIds = state.foodsAndCategories
          ?.map((item) => {
            if (item._id === state.selectedFood?.categoryId) {
              return item.category[0]?.tax_categories;
            }
            return undefined;
          })
          .filter((item) => item !== undefined);
      }
    },
    getMdifiers(state, action) {
      state.loading = false;
    },
    getMdifiersSucceeded(state, action) {
      state.loading = false;
      state.modifiersDetails = action.payload;
    },
    getMdifiersFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getTaxes(state, action) {
      state.loading = false;
    },
    getTaxesSucceeded(state, action) {
      state.loading = false;
      state.taxesInDetails = action.payload;
    },
    getTaxesFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getFoodsAndCategoriesBySearch(state, action) {
      state.getFoodsInProgress = true;
    },

    getRecomendedFoods(state, action) {
      state.errorMessage = null;
    },
    getRecomendedFoodsSucceeded(state, action) {
      state.recomendedFoods = action.payload;
    },
    getRecomendedFoodsFailed(state, action) {
      state.errorMessage = action.payload;
    },
    clearRecomendedFoods(state) {
      state.recomendedFoods = [];
    },
    cleanModifiers(state) {
      state.modifiersDetails = null;
    },
    cleanTaxes(state) {
      state.taxesInDetails = null;
    },
    getPromotions(state, action) {
      state.errorMessage = null;
      state.promotions = [];
    },
    getPromotionsSucceeded(state, action) {
      state.promotions = action.payload;
    },
    getPromotionsFailed(state, action) {
      state.errorMessage = action.payload;
    },
    clearPromotions(state) {
      state.promotions = [];
    },
    validateStore(state, action) {
      state.loading = true;
    },
    validateStoreSucceeded(state, action) {
      state.loading = false;
      state.storeValidated = true;
      state.selectedStore = action.payload;
    },
    validateStoreFailed(state, action) {
      state.loading = false;
    },
  },
});

export const { actions: menuActions } = slice;

export const useMenuSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: menuSaga });
  return { actions: slice.actions };
};
