import { socket } from './index';
import { getUuid } from './UuidService';
import { store } from '../Redux/redux-middleware';
import { mainActions } from '../pages/main/actions';

export const connectToSocketRoom = ({ room }) => {
  try {
    socket.emit('join-request-from-guest', { room, uuid: getUuid() });
  } catch (error) {
    console.log('Error here', error);
  }
};
export const orderAcknowledge = ({ room, orderId }) => {
  try {
    console.log('socekt msg sent');
    socket.emit('order-acknowledge', { room, uuid: getUuid(), orderId });
  } catch (error) {
    console.log('Error here', error);
  }
};

export const feedbackAcknowledge = ({ room }) => {
  try {
    socket.emit('feedback-acknowledge', { room });
  } catch (error) {
    console.log('Error here', error);
  }
};

export const requestHotAction = ({ room, hotActionId, storeId, table, restaurantId }) => {
  try {
    socket.emit('request-hot-action', { room, hotActionId, storeId, table, restaurantId });
  } catch (error) {
    console.log('Error here', error);
  }
};

socket.on('connect', () => {
  console.log('Connected to the socket server..');
  console.log(socket.id);
});
socket.on('order-recived', ({ uuid, status, orderId }) => {
  if (uuid === getUuid()) {
    console.log('Order recived', status);
    store.dispatch(mainActions.setOrderStatus({ status, orderId }));
  }
});
socket.on('existing-orders', ({ orders }) => {
  store.dispatch(mainActions.fetchExistingOrders({ orders }));
});
socket.on('update-status', (data) => {
  store.dispatch(mainActions.updateOrderStatus(data));
  console.log('yaaa got it >> ', data);
});
