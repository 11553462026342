import React, { useState } from "react";
import "../styles/cartOrders.scss";
import {
  selectCartData,
  selectIsAuthenicated,
  selectRestaurantCurrency,
} from "../../../pages/main/selectors";
import { useSelector, useDispatch } from "react-redux";
import configs from "../../../configs";
import CartFoodCard from "../../../components/CartFoodCard";
import emptyCart from "../../../images/empty_cart.png";
import { MODAL_STATUS } from "../constants";
import { useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useMainSlice } from "../../../pages/main/actions";
import OpeningHours from "../../OpeningHours";
import {
  selectSelectedStore,
  selectIsLoading,
} from "../../../pages/menuPage/selectors";
import moment from "moment";
import ButtonCirculerLoader from "../../ButtonCirculerLoader";
import { Collapse } from "reactstrap";
import { getCurrentDay } from "../../../utils/helpers/common";
import DiscountCard from "../../DiscountCard";
import { selectOrderSetting } from "../../../pages/main/selectors";

// authenticated
export default function CartOrders(props) {
  const cartData = useSelector(selectCartData);
  const { actions } = useMainSlice();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenicated);
  const currency = useSelector(selectRestaurantCurrency);
  // const [total, setTotal] = useState(0);
  const { table } = queryString.parse(window.location.search);
  const storeId = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
  const { restaurantUrl } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [openingHours, setOpeningHours] = useState(false);
  const selectedStore = useSelector(selectSelectedStore);
  const loading = useSelector(selectIsLoading);
  const orderSetting = useSelector(selectOrderSetting);

  const placeOrder = () => {
    if (table || isAuthenticated) {
      props.setModalStatus(MODAL_STATUS.SELECT_PAYMENT_TYPE);
    } else {
      history.push(`/${restaurantUrl}/login?store=${storeId}&cart=true`);
    }
  };
  const setTip = (amount) => {
    dispatch(actions.setTipAmount(amount));
  };

  const checkShopOpen = () => {
    let isOpen = false;
    const day = getCurrentDay();
    if (selectedStore?.openingHours?.[day].open) {
      var format = "HH:mm";
      var currentTime = moment().format(format);
      var time = moment(currentTime, format);
      selectedStore?.openingHours?.[day]?.slots.forEach((element) => {
        let beforeTime = moment(element.start, format);
        let afterTime = moment(element.end, format);
        if (
          time.isBetween(beforeTime, afterTime) ||
          beforeTime.format(format) === currentTime ||
          afterTime.format(format) === currentTime
        ) {
          isOpen = true;
          return;
        }
      });
    }
    return isOpen;
  };

  return (
    <div className="cart-orders-wrapper">
      <h4>{t("cart.my_cart")}</h4>
      {!checkShopOpen() && !loading ? (
        <>
          <div className={`info-container ${openingHours && "expanded"}`}>
            <p>{t("cart.opening_hours_info")}</p>
            <div
              className="link"
              onClick={() => {
                setOpeningHours(!openingHours);
              }}
            >
              <span>{t("cart.see_opening_hours")}</span>
            </div>
          </div>
          {openingHours && (
            <Collapse isOpen={openingHours} className="hours-wrapper">
              <div>{t("cart.opening_hours")}</div>
              <OpeningHours />
            </Collapse>
          )}
        </>
      ) : null}

      {/* {!locationDetails?.data && !loading ? (
        <>
          <div className={`info-container`}>
            <p>
              {locationDetails?.error ? locationDetails?.error : ""}
              <br /> {t("cart.enable_location_info")}
            </p>
            <div
              className="link"
              onClick={() => {
                // setOpeningHours(!openingHours);
              }}
            >
              <span>{t("cart.enable_location")}</span>
            </div>
          </div>
        </>
      ) : null} */}

      {cartData.items.length > 0 ? (
        <>
          <div className="foodCardList">
            {cartData?.items.map((item, index) => (
              <CartFoodCard key={index} data={item} index={index} />
            ))}
          </div>
          <button className="add-more" onClick={() => props.closeModal()}>
            {t("food_description.add-more")}
          </button>
          <div>
            {cartData?.promotions?.map((promotion, index) => {
              return <DiscountCard data={promotion} key={index} />;
            })}
          </div>
          {orderSetting?.customerTip && (
            <div className="tips-section">
              <h6>{t("food_description.add_tip")}</h6>
              <div>
                <button
                  onClick={() =>
                    setTip(Math.round(cartData.sub_total * 0.05 * 100) / 100)
                  }
                >
                  5%
                </button>
                <button
                  onClick={() =>
                    setTip(Math.round(cartData.sub_total * 0.1 * 100) / 100)
                  }
                >
                  10%
                </button>
                <button
                  onClick={() =>
                    setTip(Math.round(cartData.sub_total * 0.2 * 100) / 100)
                  }
                >
                  20%
                </button>
                <button
                  onClick={() =>
                    setTip(Math.round(cartData.sub_total * 0.25 * 100) / 100)
                  }
                >
                  25%
                </button>
                <button
                  onClick={() =>
                    setTip(Math.round(cartData.sub_total * 0.3 * 100) / 100)
                  }
                >
                  30%
                </button>
              </div>
              <input
                onChange={(e) => {
                  if (parseFloat(e.target.value) > 0) {
                    setTip(Math.round(parseFloat(e.target.value) * 100) / 100);
                  } else {
                    setTip(0.0);
                  }
                }}
                value={cartData.tip > 0 ? cartData.tip : null}
                placeholder={t("food_description.or_amount")}
                type="number"
              />
            </div>
          )}
          <div className="netAmount">
            <div className="subSec">
              <h6>{t("cart.sub_total")}</h6>
              <h6>
                {currency} {cartData.sub_total}
              </h6>
            </div>
            {cartData.discount > 0 ? (
              <div className="subSec">
                <h6>{t("cart.discount")}</h6>
                <h6>
                  - {currency} {cartData.discount}
                </h6>
              </div>
            ) : null}

            {cartData.tip > 0 ? (
              <div className="subSec">
                <h6>{t("cart.tip")}</h6>
                <h6>
                  {currency} {cartData.tip}
                </h6>
              </div>
            ) : null}
            {cartData.tax_included > 0 && (
              <div className="subSec">
                <h6>{t("cart.tax_included")}</h6>
                <h6>
                  {currency} {cartData.tax_included}
                </h6>
              </div>
            )}
            {cartData.tax_not_included > 0 && (
              <div className="subSec">
                <h6>{t("cart.tax_not_included")}</h6>
                <h6>
                  {currency} {cartData.tax_not_included}
                </h6>
              </div>
            )}
            <div className="subSec total">
              <h4>{t("cart.total")}</h4>
              <h6>
                {currency} {cartData.total}
              </h6>
            </div>
            <button
              className="main-button"
              onClick={placeOrder}
              disabled={!checkShopOpen() || loading}
            >
              {loading && <ButtonCirculerLoader />} {t("cart.place_order")}
            </button>
          </div>
        </>
      ) : (
        <div className="no-data">
          {/* <h6>{`Your ${restaurantName} Cart is empty`}</h6> */}
          <img src={emptyCart} alt=""></img>
          <p>{t("cart.shop_today_deals")}</p>
        </div>
      )}
    </div>
  );
}
