import React, { useState } from 'react';
import '../styles/promoSection.scss';
import { selectCurrentPromotions } from '../../main/selectors';
import { useSelector } from 'react-redux';
import configs from '../../../configs';
import Carousel from 'react-multi-carousel';
import { Modal } from 'reactstrap';
import { MdOutlineClose } from 'react-icons/md';
import discount from '../../../images/discount.png';
import 'react-multi-carousel/lib/styles.css';
import i18next from 'i18next';

export default function PromoSection() {
  const promos = useSelector(selectCurrentPromotions);
  const [promoToModal, setPromoToModal] = useState(null);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };
  const PromoCard = ({ data }) => {
    const promoCardStyle = {
      backgroundImage: `url(${
        configs.RESOURCES_BUCKET_URL + '/' + data.imageUrl
      })`,
    };
    return (
      <div
        className="promoCard-container"
        onClick={() => setPromoToModal(data)}
        style={promoCardStyle}
      >
        <div className="promoCard">
          <div className="text">
            <h4>
              {data.name?.[i18next.language || 'en'] || data.name?.['en']}
            </h4>
          </div>
        </div>
      </div>
    );
  };
  const PromoModal = () => {
    return (
      <Modal
        isOpen={promoToModal ? true : false}
        className="promo-modal-wrapper"
      >
        <MdOutlineClose
          className="closeIcon"
          onClick={() => setPromoToModal(null)}
        />
        <img
          src={configs.RESOURCES_BUCKET_URL + '/' + promoToModal.imageUrl}
          alt=""
        />
        <div className="promo-details">
          <img src={discount} alt="" />
          <h3>{promoToModal.name?.[i18next.language || 'en']}</h3>
          <p>{promoToModal.description?.[i18next.language || 'en']}</p>
        </div>
      </Modal>
    );
  };
  return (
    <div className="promoCards-wrapper">
      <Carousel
        responsive={responsive}
        className="promoCard-carousel"
        autoPlay={true}
        arrows={false}
        infinite={true}
      >
        {promos?.map((item, index) => (
          <PromoCard key={index} data={item} />
        ))}
      </Carousel>
      {promoToModal && <PromoModal />}
    </div>
  );
}
