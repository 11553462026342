import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './actions';

const selectSlice = (state) => state?.answer || initialState;

export const selectAnswerLoading = createSelector(
  [selectSlice],
  (state) => state.loading
);
