import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterSquare,
} from 'react-icons/ai';
import { IoLogoFoursquare } from 'react-icons/io';
import {
  FaTripadvisor,
  FaSnapchatSquare,
  FaTiktok,
  FaPinterestP,
} from 'react-icons/fa';
import { SiZomato } from 'react-icons/si';
import moment from 'moment';

export const getCurrentDay = () => {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  const today = new Date();
  return days[today.getDay()];
};

export const getCurrentFormatDay = () => {
  const today = new Date();
  const time = moment().format('HH:MM');
  return {
    day: today.getDay(),
    time: hmsToSecondsOnly(time),
  };
};

export const hmsToSecondsOnly = (hm) => {
  var a = hm.split(':');
  return +a[0] * 60 * 60 + +a[1] * 60;
};

export const getSocialAccount = (name) => {
  let account = null;
  switch (name) {
    case 'facebook':
      account = {
        icon: <AiFillFacebook size={30} />,
        path: 'https://www.facebook.com/',
      };
      break;
    case 'twitter':
      account = {
        icon: <AiFillTwitterSquare size={30} />,
        path: 'https://twitter.com/',
      };
      break;
    case 'pinterest':
      account = {
        icon: <FaPinterestP size={30} />,
        path: 'https://pinterest.com/',
      };
      break;
    case 'instagram':
      account = {
        icon: <AiFillInstagram size={30} />,
        path: 'https://www.instagram.com/',
      };
      break;
    case 'foursquare':
      account = {
        icon: <IoLogoFoursquare size={30} />,
        path: 'https://foursquare.com/',
      };
      break;
    case 'tripadvisor':
      account = {
        icon: <FaTripadvisor size={30} />,
        path: 'https://tripadvisor.com/',
      };
      break;
    case 'tiktok':
      account = {
        icon: <FaTiktok size={30} />,
        path: 'https://www.tiktok.com/@',
      };
      break;
    case 'snapchat':
      account = {
        icon: <FaSnapchatSquare size={30} />,
        path: 'https://snapchat.com/add/',
      };
      break;
    case 'zomato':
      account = {
        icon: <SiZomato size={30} />,
        path: 'https://www.zomato.com/',
      };
      break;
    default:
      account = null;
  }
  return account;
};
