import React from "react";
import "../styles/orderDetailsCard.scss";
import moment from "moment";
import OrderSteper from "./OrderSteper";
import { useTranslation } from "react-i18next";
import { selectRestaurantCurrency } from "../../../pages/main/selectors";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";

export default function OrderDetailsCard({ data, onClick }) {
  const currency = useSelector(selectRestaurantCurrency);
  const { t } = useTranslation();

  return (
    <div
      className="order-details-card-wrapper"
      onClick={() => {
        onClick(data);
      }}
    >
      <div className="top">
        <h6>
          {t("cart.order_id")} : <span>{data._id}</span>{" "}
        </h6>
        <h6>{`${currency}  ${Number.parseFloat(
          Math.round(data.total * 100) / 100,
        ).toFixed(2)}`}</h6>
      </div>
      <div className="bottom">
        <p>{moment(data.createdAt).format("DD of MMM, YYYY hh:mm a")} </p>
        <h6>
          {data.orderItems?.length} {t("cart.items")}
        </h6>
      </div>
      {data.status === "CANCELED" ? (
        <Badge className="cancel-badge">CANCELED</Badge>
      ) : (
        <OrderSteper status={data.status} />
      )}
    </div>
  );
}
