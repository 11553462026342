import React, { useState } from "react";
import "./styles.scss";

const ButtonGroup = ({buttons,onChange}) => {
    const [clickedId, setClickedId] = useState(-1);
    const handleClick = (event, id) => {
        setClickedId(id);
        onChange(event.target.name)
      };

  return (
    <div className="qs btn-group-container">
      {buttons.map((label,index) => {
        return <button
        type="button"
        key={index}
        name={label}
        onClick={(event) => handleClick(event, index)}
        className={index === clickedId ? "active-button" : ""}
        >{label}</button>;
      })}
    </div>
  );
};

export default ButtonGroup;
