import React, { useState } from "react";
import { selectOrdersInProgressCount } from "../../pages/main/selectors";
import { useSelector } from "react-redux";
import "./styles.scss";
import OrderDetailsModal from "../OrderDetailsModal";
import { useTranslation } from "react-i18next";

export default function OrdersCountModal() {
  const [orderDdetailsOpen, setOrderDetailsOpen] = useState(false);
  const orderCountinProgress = useSelector(selectOrdersInProgressCount);
  const { t } = useTranslation();

  return (
    <div
      className="OrdersCountModal-wrapper"
      onClick={() => setOrderDetailsOpen(true)}
    >
      <h6>
        {t("cart.orders")} <span>{orderCountinProgress}</span>
      </h6>
      {orderDdetailsOpen && (
        <OrderDetailsModal
          isOpen={orderDdetailsOpen}
          toggle={() => setOrderDetailsOpen(!OrderDetailsModal)}
        />
      )}
    </div>
  );
}
