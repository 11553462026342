import React from "react";
import FoodCard from "../../../components/FoodCard";
import "../styles/foodCategory.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Fade } from "react-awesome-reveal";

export default function FoodsInCategory({ data, enableOrdering }) {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  return (
    <div className="foodCategory-wrapper">
      <Fade duration={300} cascade>
        <h4>{data.category[0]?.name[i18next.language || "en"]}</h4>
        {data.category[0]?.description ? (
          <p>{data.category[0]?.description[i18next.language || "en"]}</p>
        ) : (
          <div />
        )}
        <hr />
        <div className="foods-section">
          {data.foods.map((_food, index) => (
            <FoodCard
              key={index}
              data={_food}
              enableOrdering={enableOrdering}
            />
          ))}
        </div>
      </Fade>
    </div>
  );
}
