import { useTranslation } from 'react-i18next';
import configs from '../../../configs';

import './styles.scss';

const LearnMore = () => {
  const { t } = useTranslation();

  const goToMainSite = () => {
    window.open(
      configs.MAIN_SITE_URL,
      '_blank' 
    );
  }

  return (
    <div className="go-to-main-website-container">
      <div className='powerdBy'><p>{t('footer.powerd_by')}</p></div>
      <div className="logo-img">
        <img onClick={goToMainSite} src="https://menutigr-resources.s3.us-west-2.amazonaws.com/mt-name-logo.png" alt="menu-tiger-logo" width={100}/>
      </div>
    </div>
  )
};

export default LearnMore;
