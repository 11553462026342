import React from 'react';
import '../styles/testimonialSection.scss';
import data from '../../main/data.json';
import Carousel from 'react-multi-carousel';
import { FaQuoteLeft } from 'react-icons/fa';
import i18next from 'i18next';

export default function TestiminialSection() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 780 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 780, min: 0 },
      items: 1,
    },
  };
  const TestimonialCard = () => (
    <div className="testimonialCard-wrapper">
      <p>{data.testimonials.text[i18next.language || 'en']}</p>
      <div>
        <div>
          <img
            src="https://ichef.bbci.co.uk/news/976/cpsprodpb/9B30/production/_119782793_gettyimages-1227843827.jpg"
            alt=""
          />
          <h4>John Dore</h4>
        </div>
        <FaQuoteLeft className="quotemark" />
      </div>
    </div>
  );
  const TestimonialsCarosel = () => (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      infinite={true}
      arrows={false}
    >
      <TestimonialCard />
      <TestimonialCard />
      <TestimonialCard />
      <TestimonialCard />
    </Carousel>
  );
  return (
    <div className="home-testimonials-wrapper">
      <h2>{data.testimonials.header[i18next.language || 'en']}</h2>
      <hr />
      <p>{data.testimonials.text[i18next.language || 'en']}</p>
      <div>
        <TestimonialsCarosel />
      </div>
    </div>
  );
}
