import React from 'react';
import './styles.scss';
import { Row, Col, Container } from 'reactstrap';
import {
  MdOutlineDateRange,
  MdOutlineLocationOn,
  MdOutlineContactPage,
} from 'react-icons/md';
import {
  selectRestaurantData,
  selectRestaurantName,
  selectContactDetails,
  selectWhiteLabelEnabled,
  selectSubscriptionPlan,
} from '../../pages/main/selectors';
import configs from '../../configs';
import { useSelector } from 'react-redux';
import { selectSelectedStore } from '../../pages/menuPage/selectors';
import { useHistory, useParams } from 'react-router-dom';
import i18next from 'i18next';
import OpeningHours from '../OpeningHours';
import { getSocialAccount } from '../../utils/helpers/common';
import { useTranslation } from 'react-i18next';
import LearnMore from './LearnMore';

export default function Footer() {
  const history = useHistory();
  const selectedStore = useSelector(selectSelectedStore);
  const { restaurantUrl } = useParams();
  const restaurantData = useSelector(selectRestaurantData);
  const whiteLabelEnabled = useSelector(selectWhiteLabelEnabled);
  const restaurantName = useSelector(selectRestaurantName);
  const contactDetails = useSelector(selectContactDetails);
  const subscriptionPlan = useSelector(selectSubscriptionPlan);
  const { t } = useTranslation();
  const logoUrl = () => {
    let url = null;
    if (history.location.pathname === `/${restaurantUrl}`) {
      url = restaurantData?.logoUrl ? restaurantData?.logoUrl : null;
    } else {
      if (selectedStore?.logoUrl) {
        url = selectedStore?.logoUrl;
      } else if (restaurantData?.logoUrl) {
        url = restaurantData?.logoUrl;
      }
    }
    return url;
  };

  return (
    <Container className="footer-wrapper" fluid>
      <Container className="footer-container">
        <Row>
          <Col sm="5" className="footer-box">
            <div className="logo">
              {logoUrl() ? (
                <img
                  src={configs.RESOURCES_BUCKET_URL + '/' + logoUrl()}
                  alt=""
                />
              ) : (
                <h5>{restaurantName}</h5>
              )}
            </div>
            {history.location.pathname !== `/${restaurantUrl}` && (
              <div className="social-media">
                {Object.keys(selectedStore?.socialAccounts || {}).map(
                  // eslint-disable-next-line array-callback-return
                  (name, index) => {
                    if (selectedStore?.socialAccounts?.[name] !== '') {
                      const accountData = getSocialAccount(name);
                      if (accountData) {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              window.open(
                                `${accountData.path}${selectedStore?.socialAccounts?.[name]}`,
                                '_blank'
                              );
                            }}
                          >
                            {accountData.icon}
                          </div>
                        );
                      }
                    }
                  }
                )}
              </div>
            )}
          </Col>
          {history.location.pathname !== `/${restaurantUrl}` && (
            <Col sm="3" className="footer-box">
              <div>
                <MdOutlineLocationOn /> {t('footer.locations')}{' '}
              </div>
              <p>
                {history.location.pathname === `/${restaurantUrl}`
                  ? restaurantData?.address
                  : selectedStore?.address?.[i18next.language || 'en']}
              </p>
              <>
                <div>
                  {' '}
                  <MdOutlineContactPage /> {t('footer.contacts')}{' '}
                </div>
                <p>
                  {t('footer.email')} : {contactDetails.email}
                  <br />
                  {t('footer.phone')} : {contactDetails.phoneNumber}
                </p>
              </>
            </Col>
          )}
          <Col sm="4" className="footer-box">
            {history.location.pathname !== `/${restaurantUrl}` && (
              <>
                <div>
                  {' '}
                  <MdOutlineDateRange />
                  {t('footer.openinghours')}{' '}
                </div>

                <OpeningHours />
              </>
            )}
          </Col>
        </Row>
      </Container>
      <div className="bottom-container">
        {(subscriptionPlan === 'FREEMIUM' || !whiteLabelEnabled) && (
          <LearnMore />
        )}
      </div>
      <div className="bottom">
        <p>{t('footer.copy_rights')}</p>
      </div>
    </Container>
  );
}
