import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import "./styles.scss";
const PhoneNumberInput = ({
  control,
  validationRules,
  name,
  defaultCountry = "US",
  countryCallingCodeEditable = false,
  label,
  requried,
  info,
  vertical,
  errors,
  defaultValue,
}) => {
  return (
    <div className={`phoneInputBox ${errors?.[name] ? "formError" : ""}`}>
      <PhoneInputWithCountry
        defaultValue={defaultValue}
        control={control}
        rules={validationRules}
        name={name}
        international
        countryCallingCodeEditable={countryCallingCodeEditable}
        defaultCountry={defaultCountry}
        placeholder="Enter phone number"
      />
    </div>
  );
};

export default PhoneNumberInput;
