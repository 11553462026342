export const handleScrollOnHomeNavBar = () => {
  const offset = window.pageYOffset;
  if (offset > 80) {
    const elmnt = document.getElementById('home-navBar');
    elmnt.style.opacity = '0.658';
    elmnt.style.height = '55px';
  } else {
    const elmnt = document.getElementById('home-navBar');
    elmnt.style.opacity = '0.9';
    elmnt.style.height = '60px';
  }
};
