import React from 'react';
import AboutSection from './components/AboutSection';
import HeroSection from './components/HeroSection';
import WhyWeChooseUs from './components/WhyWeChooseUs';
import FeaturedSection from './components/FeaturedSection';
import TestiminialSection from './components/TestiminialSection';
import ContactUsSection from './components/ContactUsSection';
import './styles.scss';
import { useSelector } from 'react-redux';
import {
  selectRestaurantData,
  selectCurrentPromotions,
} from '../main/selectors';
import PromoSection from './components/PromoSection';
import { Fade } from 'react-awesome-reveal';

export default function HomePage() {
  const restaurantData = useSelector(selectRestaurantData);
  const promos = useSelector(selectCurrentPromotions);
  return (
    <div className="home-page-wrapper">
      {restaurantData.heroSection?.enable && <HeroSection />}
      {restaurantData.about?.enable && (
        <section id="about">
          <Fade duration={2000} cascade delay={200}>
            <AboutSection />
          </Fade>
        </section>
      )}
      {promos?.length > 0 && (
        <section id="promo">
          <Fade duration={2000} cascade delay={200}>
            <PromoSection />
          </Fade>
        </section>
      )}
      {restaurantData.bestMenu?.enable && (
        <section id="featured">
          <Fade duration={2000} cascade delay={200}>
            <FeaturedSection />
          </Fade>
        </section>
      )}
      {restaurantData.whyChooseUs?.enable && (
        <section id="whyus">
          <Fade duration={2000} cascade delay={200}>
            <WhyWeChooseUs />
          </Fade>
        </section>
      )}
      {restaurantData.testimonials?.enable && <TestiminialSection />}
      {true && (
        <section id="contact">
          <Fade duration={2000} cascade delay={200}>
            <ContactUsSection />
          </Fade>
        </section>
      )}
    </div>
  );
}
