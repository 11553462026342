import React, { useEffect, useState } from "react";
import "../styles/singleOrderView.scss";
import moment from "moment";
import OrderSteper from "./OrderSteper";
import { useTranslation } from "react-i18next";
import {
  selectRestaurantCurrency,
  selectOrderSetting,
  selectOrderEditingSucceeded,
  selectOrderEditingInProgress,
} from "../../../pages/main/selectors";
import { useSelector, useDispatch } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import SingleOrderItem from "./SingleOrderItem";
import { Modal, Badge } from "reactstrap";
import { useMainSlice } from "../../../pages/main/actions";
import ButtonCirculerLoader from "../../ButtonCirculerLoader";
import DiscountCard from "../../DiscountCard";

export default function SingleOrderView({ data, onBack }) {
  const currency = useSelector(selectRestaurantCurrency);
  const setting = useSelector(selectOrderSetting);
  const loading = useSelector(selectOrderEditingInProgress);
  const orderEditingSucceeded = useSelector(selectOrderEditingSucceeded);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const { actions } = useMainSlice();
  const dispatch = useDispatch();

  const toggle = () => {
    setOpen(!isOpen);
  };

  const onCancel = () => {
    dispatch(
      actions.editOrder({
        id: data._id,
        data: {
          status: "CANCELED",
        },
      }),
    );
  };

  useEffect(() => {
    if (orderEditingSucceeded) {
      dispatch(actions.clearEditingSucceeded());
      toggle();
      onBack();
    }
  });

  return (
    <div className="single-order-wrapper">
      <div className="header">
        <IoIosArrowBack size={25} onClick={onBack} />
        <div>
          {" "}
          {"ID"} : <span>{data._id}</span>{" "}
          <p>
            {moment(data.createdAt).format("DD of MMM, YYYY hh:mm a")}{" "}
            {data.status === "CANCELED" && (
              <Badge className="cancel-badge">CANCELED</Badge>
            )}
          </p>
        </div>
      </div>

      {data.status !== "CANCELED" && (
        <div className="steper-container">
          <OrderSteper status={data.status} />
        </div>
      )}

      <div>Order Items</div>
      <div className="item-container">
        {data?.items?.map((item, index) => {
          return (
            <>
              <SingleOrderItem key={index} data={item} />
            </>
          );
        })}
      </div>

      <div style={{ marginTop: "1em" }}>
        {data?.promotions.map((promotion, index) => {
          return <DiscountCard data={promotion} key={index} />;
        })}
      </div>
      <div className="netAmount">
        <div className="subSec">
          <h6>{t("cart.sub_total")}</h6>
          <h6>
            {currency} {Number.parseFloat(data.sub_total).toFixed(2)}
          </h6>
        </div>
        {data?.tip > 0 && (
          <div className="subSec">
            <h6>{t("cart.tip")}</h6>
            <h6>
              {currency} {data.tip}
            </h6>
          </div>
        )}
        {data?.discount > 0 && (
          <div className="subSec">
            <h6>{t("cart.discount")}</h6>
            <h6>
              - {currency} {data?.discount.toFixed(2)}
            </h6>
          </div>
        )}
        {data?.tax_included > 0 && (
          <div className="subSec">
            <h6>{t("cart.tax_included")}</h6>
            <h6>
              + {currency} {data?.tax_included.toFixed(2)}
            </h6>
          </div>
        )}
        {data?.tax_not_included > 0 && (
          <div className="subSec">
            <h6>{t("cart.tax_not_included")}</h6>
            <h6>
              + {currency} {data?.tax_not_included.toFixed(2)}
            </h6>
          </div>
        )}
        <div className="subSec total">
          <h4>{t("cart.total")}</h4>
          <h6>
            {currency}{" "}
            {Number.parseFloat(Math.round(data.total * 100) / 100).toFixed(2)}
          </h6>
        </div>
      </div>
      <div className="footer-container">
        {data.status === "PENDING" && setting?.cancelOrder && (
          <button
            className="main-light-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            {t("cart.cancel_order")}
          </button>
        )}
      </div>
      {isOpen && setting?.cancelOrder && (
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle();
          }}
        >
          <div className="body-container">
            <h4>{t("cart.confirm_to_cancel")}</h4>
            <p>{t("cart.confirm_to_cancel_info")}</p>

            <div className="btn-container">
              <button
                className="main-light-button"
                onClick={() => {
                  toggle();
                }}
              >
                {t("cart.cancel")}
              </button>{" "}
              <button
                className="main-button"
                onClick={onCancel}
                disabled={loading}
              >
                {t("cart.yes")}
                {loading && <ButtonCirculerLoader />}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
