import React from "react";
import "./styles.scss";
import logo from '../../images/logo.svg'
export default function NotFoundPage() {
  return (
    <div className="--NOTFOUNDPAGE">
      <div/>
      <div className="--NOTFOUNDPAGE_CONTENT">
        <img alt="" src={logo} />
        <h3>Welcome to Menu Tiger!</h3>
        <a
          target="_blank"
          href="https://menu.qrcode-tiger.com"
          rel="noreferrer"
        >
          Create your own digital Menu
        </a>
        <a
          target="_blank"
          href="https://menu.qrcode-tiger.com/blog/"
          rel="noreferrer"
        >
          Read our blogs for more insights
        </a>
        <a
          target="_blank"
          href="https://menu.qrcode-tiger.com/contact/"
          rel="noreferrer"
        >
          Contact our support team
        </a>
      </div>
      <div className="--NOTFOUNDPAGE_FOOTER">
        <p>This service is managed by Menu Tiger</p>
      </div>
    </div>
  );
}