import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './actions';

const selectSlice = (state) => state?.menu || initialState;

export const selectMenu = createSelector([selectSlice], (state) => state);

export const selectIsLoading = createSelector(
  [selectSlice],
  (state) => state.loading
);
export const selectSelectedStore = createSelector(
  [selectSlice],
  (state) => state.selectedStore
);
export const selectMenuId = createSelector(
  [selectSlice],
  (state) => state.selectedStore?.menuId._id
);
export const selectFoodsAndCategories = createSelector(
  [selectSlice],
  (state) => state.foodsAndCategories
);
export const selectSelectedFood = createSelector(
  [selectSlice],
  (state) => state.selectedFood
);
export const selectModifiersInCategory = createSelector(
  [selectSlice],
  (state) => state.categoryModifierIds || []
);
export const selectTaxIdsInCategory = createSelector(
  [selectSlice],
  (state) => state.categoryTaxIds || []
);
export const selectModifiersDetails = createSelector(
  [selectSlice],
  (state) => state.modifiersDetails
);

export const selectRecomendedFoods = createSelector(
  [selectSlice],
  (state) => state.recomendedFoods
);
export const selectPromotions = createSelector(
  [selectSlice],
  (state) => state.promotions
);
export const selectGetFoodsInProgress = createSelector(
  [selectSlice],
  (state) => state.getFoodsInProgress
);
export const selectStoreValidated = createSelector(
  [selectSlice],
  (state) => state.storeValidated
);
export const selectTaxesInDetails = createSelector(
  [selectSlice],
  (state) => state.taxesInDetails || []
);
export const selectEnableOrdering = createSelector(
  [selectSlice],
  (state) => state.selectedStore?.settings?.order?.enableOrdering
);
