import React, { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import "./styles.scss";

const StarRating = ({ starCount, onChange }) => {
  const [rating, setRating] = useState(-1);
  const [hover, setHover] = useState(-1);
  const handleClick = (index) => {
    setRating(index);
    onChange(index+1);
  };

  return (
    <div className="rating-holder">
      <div className="rating-bar">
        {Array(starCount)
          .fill()
          .map((el, index) => (
            <div
              className={`rating-icon ${
                rating >= index || hover >= index ? "rotate" : ""
              }`}
              key={index + 1}
              onClick={() => handleClick(index)}
              onMouseOver={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            >
              {rating >= index || hover >= index ? (
                <AiFillStar size={35} />
              ) : (
                <AiOutlineStar size={35} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default StarRating;
