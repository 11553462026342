import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './actions';

const selectSlice = (state) => state?.home || initialState;

export const selectHome = createSelector([selectSlice], (state) => state);

export const selectIsLoading = createSelector(
  [selectSlice],
  (state) => state.loading
);
export const selectFeaturedFoods = createSelector(
  [selectSlice],
  (state) => state.featuredFoods
);
export const selectOpenStoreSelector = createSelector(
  [selectSlice],
  (state) => state.openStoreSelector
);
export const selectSavingNewsletter = createSelector(
  [selectSlice],
  (state) => state.savingNewsletter
);
export const selectNewsletterSaved = createSelector(
  [selectSlice],
  (state) => state.newsletterSaved
);
