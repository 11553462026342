import { call, put, takeLatest, select } from "redux-saga/effects";
import { API } from "./constants";
import { menuActions as actions } from "./actions";
import { request } from "../../utils/request";
import configs from "../../configs";
import { selectRestaurantUrl } from "../main/selectors";

function* getStoreData(action) {
  try {
    const result = yield call(
      request,
      API.GET_STORE_DATA,
      { storeId: action.payload.storeId },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getStoreDataSucceeded(result.data));
    } else {
      yield put(actions.getStoreDataFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getStoreDataFailed(e.message));
  }
}
function* getFoodsAndCategories(action) {
  try {
    const result = yield call(
      request,
      API.GET_FOODS_AND_CATEGORIES,
      {
        storeId: action.payload.storeId,
        menuId: action.payload.menuId,
        option: action.payload.option,
        currentDateTime: new Date(),
      },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getFoodsAndCategoriesSucceeded(result.data));
    } else {
      yield put(actions.getFoodsAndCategoriesFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getFoodsAndCategoriesFailed(e.message));
  }
}
function* getMdifiers(action) {
  try {
    const result = yield call(
      request,
      API.GET_MODIFERS,
      { modifierIds: action.payload },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getMdifiersSucceeded(result.data));
    } else {
      yield put(actions.getMdifiersFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getMdifiersFailed(e.message));
  }
}
function* getTaxes(action) {
  try {
    const result = yield call(
      request,
      API.GET_TAXES,
      { taxIds: action.payload },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getTaxesSucceeded(result.data));
    } else {
      yield put(actions.getTaxesFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getTaxesFailed(e.message));
  }
}

function* getFoodsAndCategoriesBySearch(action) {
  try {
    const result = yield call(
      request,
      API.GET_FOODS_AND_CATEGORIES_BY_SEARCH,
      { ...action.payload, currentDateTime: new Date() },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getFoodsAndCategoriesSucceeded(result.data));
    } else {
      yield put(actions.getFoodsAndCategoriesFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getFoodsAndCategoriesFailed(e.message));
  }
}

function* getRecomendedFoods(action) {
  try {
    const result = yield call(
      request,
      API.GET_RECOMENDED_FOODS,
      action.payload,
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getRecomendedFoodsSucceeded(result.data));
    } else {
      yield put(actions.getRecomendedFoodsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getRecomendedFoodsFailed(e.message));
  }
}
function* getPromotions(action) {
  try {
    const result = yield call(
      request,
      API.GET_PROMOTIONS_BY_FOOD,
      { ...action.payload, currentTime: new Date().toISOString() },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.getPromotionsSucceeded(result.data));
    } else {
      yield put(actions.getPromotionsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getPromotionsFailed(e.message));
  }
}
function* validateStore(action) {
  try {
    const restaurantUrl = yield select(selectRestaurantUrl);
    const result = yield call(
      request,
      API.VALIDATE_STORE,
      { ...action.payload },
      false,
    ) || {};
    if (result.success) {
      yield put(actions.validateStoreSucceeded(result.data));
    } else {
      localStorage.removeItem(`store-${configs.APP_DOMAIN}`);
      localStorage.setItem(`cart-${configs.APP_DOMAIN}`, []);
      window.location.replace(window.location.origin + "/" + restaurantUrl);
      yield put(actions.validateStoreFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.validateStoreFailed(e.message));
  }
}
function* menuSaga() {
  yield takeLatest(actions.getStoreData.type, getStoreData);
  yield takeLatest(actions.getFoodsAndCategories.type, getFoodsAndCategories);
  yield takeLatest(actions.getMdifiers.type, getMdifiers);
  yield takeLatest(actions.getTaxes.type, getTaxes);

  yield takeLatest(
    actions.getFoodsAndCategoriesBySearch.type,
    getFoodsAndCategoriesBySearch,
  );
  yield takeLatest(actions.getPromotions.type, getPromotions);
  yield takeLatest(actions.getRecomendedFoods.type, getRecomendedFoods);
  yield takeLatest(actions.validateStore.type, validateStore);
}

export default menuSaga;
