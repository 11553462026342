import React from "react";
import data from "../../main/data.json";
import "../styles/aboutSection.scss";
import {
  selectHomeAboutSection,
  selectRestaurantName,
} from "../../main/selectors";
import { useSelector, useDispatch } from "react-redux";
import configs from "../../../configs";
import { useHomeSlice } from "../actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function AboutSection() {
  const homeAboutSection = useSelector(selectHomeAboutSection);
  const restaurantname = useSelector(selectRestaurantName);

  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { t } = useTranslation();

  const openMenu = () => {
    dispatch(actions.toggleStoreSelector());
  };

  return (
    <>
      {data && (
        <div className="about-section-wrapper">
          <div className="about-text-section">
            <h5>
              {t("homePage.aboutSection.about")} {restaurantname}
            </h5>
            <h2>
              {homeAboutSection?.heading?.[i18next.language || "en"] ||
                homeAboutSection?.heading?.["en"]}
            </h2>
            <p>
              {homeAboutSection?.paragraph?.[i18next.language || "en"] ||
                homeAboutSection?.paragraph?.["en"]}
            </p>
            <button className="main-button" onClick={() => openMenu()}>
              {t("homePage.aboutSection.our_menu")}
            </button>
          </div>
          <div className="about-img-section">
            <div className="about-img-sub-section">
              <div className="main-img">
                <img
                  src={
                    configs.RESOURCES_BUCKET_URL + "/" + homeAboutSection?.image
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
