const QuestionText = ({ text, required }) => {
  return (
    <p className="question-text">
      {text}
      {required && <span className="required-text">*</span>}
    </p>
  );
};

export default QuestionText;
