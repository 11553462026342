function calculatePriceRange(priceVariants) {
  const priceList = priceVariants?.map(priceVariant =>
    Number.parseFloat(priceVariant.price),
  );

  return { min: Math.min(...priceList), max: Math.max(...priceList) };
}

function formatPriceRange(priceRange, decimalPoints = 2) {
  return `${priceRange.min.toFixed(decimalPoints)} - ${priceRange.max.toFixed(
    decimalPoints,
  )}`;
}

export { calculatePriceRange, formatPriceRange };
