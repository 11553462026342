import { v4 as uuidv4 } from 'uuid';

export const getUuid = () => {
  if (!window.localStorage.uuid) {
    window.localStorage.uuid = uuidv4();
  }
  return localStorage.getItem('uuid') || '';
};

export const removeUuid = () => {
  localStorage.removeItem('uuid');
};
