import './styles.scss';
import { selectSelectedStore } from '../../pages/menuPage/selectors';
import { useSelector } from 'react-redux';
import { getCurrentDay } from '../../utils/helpers/common';
import { useTranslation } from 'react-i18next';

const OpeningHours = () => {
  const selectedStore = useSelector(selectSelectedStore);
  const { t } = useTranslation();
  return (
    <div className="opening-hours">
      {Object.keys(selectedStore?.openingHours || {}).map((day, index) => {
        return (
          <div
            key={index}
            className={`time-range ${day === getCurrentDay() && 'selected'}`}
          >
            <div>
              <span>{t(`opening_hours.${day}`)}</span>
            </div>
            <div>
              {selectedStore?.openingHours?.[day].open
                ? selectedStore?.openingHours?.[day].slots?.map(
                    (slot, index) => (
                      <div key={index}>
                        {slot.start} - {slot.end}
                      </div>
                    )
                  )
                : `${t('opening_hours.closed')}`}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default OpeningHours;
