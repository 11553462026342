import { useDispatch, useSelector } from "react-redux";
import { QuestionText } from "./components";
import { getQuestion } from "./questionservice";
import { useForm } from "react-hook-form";
import { useAnswerSlice } from "./actions";
import { selectAnswerLoading } from "./selectors";
import {
  selectRestaurantSurveyPage,
  // selectRestaurantCoverImg,
} from "../main/selectors";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import ButtonLoader from "../../components/ButtonLoader";
import React, { useEffect } from "react";
// import configs from '../../configs';

const FeedbackPage = () => {
  const dispatch = useDispatch();
  const { actions } = useAnswerSlice();
  const surveySelection = useSelector(selectRestaurantSurveyPage);
  const loading = useSelector(selectAnswerLoading);
  // const coverImg = useSelector(selectRestaurantCoverImg);
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const saveAnswer = (data) => {
    const answerData = Object.keys(data)
      .filter((item) => data[item] != null)
      .map((item) => data[item]);

    dispatch(
      actions.saveSurveyAnswer({
        surveyName: surveySelection.name,
        answers: answerData,
      }),
    );
  };

  return (
    <div className="feedback-page-wrapper">
      {/* <div className="cover-image-container">
        <img src={configs.RESOURCES_BUCKET_URL + '/' + coverImg} alt="" />
      </div> */}
      <div className="feedback-container">
        <form onSubmit={handleSubmit(saveAnswer)}>
          <div className="feedback-wrapper">
            <h3 className="header-title">
              {surveySelection?.welcome?.[i18next.language || "en"]}
            </h3>
            {surveySelection?.questions.map((question, index) => {
              return (
                <div key={index}>
                  <QuestionText
                    text={
                      question.name?.[i18next.language] || question.name["en"]
                    }
                    required={question.required}
                  />
                  {getQuestion(
                    question.name?.[i18next.language] || question.name["en"],
                    question._id,
                    question.type,
                    control,
                    question.required,
                  )}
                </div>
              );
            })}
            <button
              disabled={!isDirty || !isValid || loading}
              type="submit"
              className="main-button"
            >
              {loading ? <ButtonLoader /> : t("homePage.feedback.submit")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackPage;
