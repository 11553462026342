import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MdOutlineClose } from 'react-icons/md';
import {
  selectRestaurantName,
  selectErrorMessage,
  selectAuthLoading,
  selectResetPasswordSecceeded,
} from '../../main/selectors';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useMainSlice } from '../../main/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import configs from '../../../configs';
import ButtonLoader from '../../../components/ButtonLoader';
import { useTranslation } from 'react-i18next';

export default function InputPassword() {
  const history = useHistory();
  const { restaurantUrl } = useParams();
  const { t } = useTranslation();
  const [passwordError, setPasswordError] = useState(null);
  const errorMessage = useSelector(selectErrorMessage);
  const restaurantName = useSelector(selectRestaurantName);
  const resetPasswordSucceeded = useSelector(selectResetPasswordSecceeded);
  const loading = useSelector(selectAuthLoading);
  const { store, email, token } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (resetPasswordSucceeded) {
      history.push(`/${restaurantUrl}/login?resetpassword=true`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordSucceeded]);
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(actions.clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = async (data) => {
    try {
      setPasswordError(null);
      if (token) {
        localStorage.setItem(`token-${configs.APP_DOMAIN}`, token);
        dispatch(actions.resetPassword({ email, password: data.password }));
      } else {
        setPasswordError(`${t('reset_password.failed_reset_password')}`);
      }
    } catch (error) {
      setPasswordError(`${t('reset_password.failed_reset_password')}`);
    }
  };
  return (
    <div className="reset-password-page-wrapper">
      <MdOutlineClose
        className="close-icon"
        onClick={() => history.push(`/${restaurantUrl}/store/${store}`)}
      />
      <h1 onClick={() => history.push(`/${restaurantUrl}/store/${store}`)}>
        {restaurantName}
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>{t('reset_password.reset_password')}</h4>
        {passwordError && <p className="error-message">{passwordError}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div>
          {email && (
            <>
              <p className="label">{t('reset_password.email')}</p>
              <input type="email" value={email} disabled />
            </>
          )}
          <p className="label">{t('reset_password.new_password')}</p>
          <input
            type="password"
            {...register('password', {
              required: true,
            })}
          />
          <div className="error">
            {errors.password?.type === 'required' && (
              <p>{t('reset_password.new_password')}</p>
            )}
          </div>
        </div>
        <button className="main-button" disabled={loading}>
          {loading ? <ButtonLoader /> : `${t('reset_password.new_password')}`}
        </button>
      </form>
    </div>
  );
}
