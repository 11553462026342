import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './locals/i18n';
import { Provider } from 'react-redux';
import { store } from './Redux/redux-middleware';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from 'react-notifications-component'

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <>
      <ReactNotifications/>
      <App />
      </>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept(['./locals/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
