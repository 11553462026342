import { QUESTION_TYPES } from "../main/constants";
import { SmileQuesion, TextBoxQuesion } from "./components";
import StarRatingQuesion from "./components/starRatingQuestion";
import YesNoQuesion from "./components/yesNoQuestion";

export const getQuestion = (question, name, type, control, required) => {
  let component = null;
  switch (type) {
    case QUESTION_TYPES.TEXT_BOX:
      component = (
        <TextBoxQuesion
          question={question}
          name={name}
          control={control}
          required={required}
        />
      );
      break;
    case QUESTION_TYPES.SMILEY:
      component = (
        <SmileQuesion
          question={question}
          name={name}
          control={control}
          required={required}
        />
      );
      break;
    case QUESTION_TYPES.STAR_RATING:
      component = (
        <StarRatingQuesion
          question={question}
          name={name}
          control={control}
          required={required}
        />
      );
      break;
    case QUESTION_TYPES.YES_NO:
      component = (
        <YesNoQuesion
          question={question}
          name={name}
          control={control}
          required={required}
        />
      );
      break;
    default:
      return null;
  }
  return component;
};

