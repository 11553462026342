import { call, put, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import i18n from "i18next";
import { API } from "./constants";
import { surveyAnserActions as actions } from "./actions";
import { request } from "../../utils/request";
import {
  selectRestaurantId,
  selectRestaurantUrl,
  selectCurrentUserStoreId,
} from "../main/selectors";
import { createBrowserHistory } from "history";
import { feedbackAcknowledge } from "../../socket/socketCtrl";
import configs from "../../configs";

const forwardBack = () => {
  const history = createBrowserHistory();
  history.goBack();
};

function* saveSurveyAnswer(action) {
  try {
    const restaurantId = yield select(selectRestaurantId);
    const restaurantUrl = yield select(selectRestaurantUrl);
    let storeURL = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
    let storeId = undefined;
    if (storeURL){
      storeId = storeURL.split("?")[0];
    }
    
    if (!storeId) {
      storeId = yield select(selectCurrentUserStoreId);
    }

    const result = yield call(
      request,
      API.SAVE_SURVEY_ANSWER,
      {
        ...action.payload,
        restaurantId: restaurantId,
        storeId,
      },
      false,
    ) || {};

    if (result.success) {
      yield put(actions.saveSurveyAnswerSucceeded(result.data));
      feedbackAcknowledge({ room: restaurantUrl }); // update notifications count after successfull feedback submit
      yield call(forwardBack);
      toast.success(i18n.t("homePage.feedback.feedback_success"), {
        position: "bottom-right",
      });
    } else {
      toast.error(i18n.t("homePage.feedback.feedback_failed"), {
        position: "bottom-right",
      });
      yield put(actions.saveSurveyAnswerFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.saveSurveyAnswerFailed(e.message));
    toast.error(i18n.t("homePage.feedback.feedback_failed"), {
      position: "bottom-right",
    });
  }
}

function* answerSaga() {
  yield takeLatest(actions.saveSurveyAnswer.type, saveSurveyAnswer);
}

export default answerSaga;
