/* eslint-disable array-callback-return */
import { FONTS_SECTIONS, COLORS_SECTIONS } from './constants';

export const setFonts = (fonts) => {
  try {
    if (fonts) {
      Object.keys(fonts).map((key) => {
        if (FONTS_SECTIONS[key]) {
          Object.keys(fonts[key]).map((_key) => {
            document.documentElement.style.setProperty(
              '--' + key + '_' + _key,
              fonts[key][_key]
            );
          });
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const setColors = (colors) => {
  try {
    if (colors) {
      Object.keys(colors).map((key) => {
        if (COLORS_SECTIONS[key]) {
          document.documentElement.style.setProperty('--' + key, colors[key]);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};
