export const API = {
  GET_FEATURED_FOODS: {
    path: '/customer-app/foods/featured',
    method: 'POST',
  },
  SAVE_NEWSLETTER: {
    path: '/customer-app/newsletter',
    method: 'POST',
  },
};
