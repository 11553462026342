import jwt_decode from 'jwt-decode';
import configs from '../configs';

export const validateToken = () => {
  try {
    const token = localStorage.getItem(`token-${configs.APP_DOMAIN}`);
    if (token) {
      const { id, exp } = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (exp >= currentTime) {
        return id;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
