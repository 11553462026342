import React from "react";
import "../styles/MenuHeroSection.scss";
import configs from "../../../configs";
import { useSelector, useDispatch } from "react-redux";
import {
  selectRestaurantCoverImg,
  selectHomeHeroSection,
  selectTable,
} from "../../main/selectors";
import { selectMenuId } from "../selectors";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Fade } from "react-awesome-reveal";
import { BsSearch } from "react-icons/bs";
import { menuActions } from "../actions";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { selectGetFoodsInProgress } from "../selectors";
import { BiLoader } from "react-icons/bi";

export default function MenuHeroSection() {
  const homeHeroSection = useSelector(selectHomeHeroSection);
  const coverImg = useSelector(selectRestaurantCoverImg);
  const loading = useSelector(selectGetFoodsInProgress);
  const selectedTable = useSelector(selectTable);
  const menuId = useSelector(selectMenuId);
  const { storeId } = useParams();
  const { table } = queryString.parse(window.location.search);
  const {
    register,
    handleSubmit,
    // setValue
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    const option = table ? "Dine-in" : "Takeaway";
    if (data.nameE !== "") {
      dispatch(
        menuActions.getFoodsAndCategoriesBySearch({
          name: data.nameE,
          storeId,
          menuId,
          option,
          lang: i18next.language,
        }),
      );
    } else {
      dispatch(
        menuActions.getFoodsAndCategoriesBySearch({ storeId, menuId, option }),
      );
    }
  };

  return (
    <div
      className="menu-hero-section-wrapper"
      // style={{
      //   backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.411), rgba(0, 0, 0, 0.411)),
      //   url(${configs.RESOURCES_BUCKET_URL}/${coverImg})`,
      // }}
    >
      {/* <img alt="" src={configs.RESOURCES_BUCKET_URL + '/' + coverImg} /> */}
      <div className="content">
        <Fade duration={2000}>
          <div className="text-section">
            {/* <h4>
              {t('menuPage.heroSection.welcome_to')} {restaurantname}
            </h4> */}
            <h1>{homeHeroSection?.heading?.[i18next.language || "en"]}</h1>
            <div className="search-wrapper-outer">
              <form onSubmit={handleSubmit(onSubmit)}>
                <button>
                  {loading ? (
                    <BiLoader className="search-icon" />
                  ) : (
                    <BsSearch className="search-icon" />
                  )}
                </button>
                <input
                  placeholder={t("menuPage.navBar.search_placeholder")}
                  {...register("nameE")}
                />
              </form>
            </div>

            {/* <hr /> */}

            {selectedTable && (
              <h4>
                {selectedTable.name
                  ? selectedTable.name
                  : `${t("menuPage.heroSection.table")} ${selectedTable.tableNumber}`}
              </h4>
            )}
            {/* <button className="main-button">Our Menu</button> */}
          </div>
        </Fade>
      </div>
      <img alt="" src={configs.RESOURCES_BUCKET_URL + "/" + coverImg} />
    </div>
  );
}
