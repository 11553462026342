import React from "react";
import data from "../../main/data.json";
import "../styles/whyWeChooseUs.scss";
import { useSelector, useDispatch } from "react-redux";
import configs from "../../../configs";
import { selectWhyChooseUsSection } from "../../main/selectors";
import { useHomeSlice } from "../actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function WhyWeChooseUs() {
  const whyChoose = useSelector(selectWhyChooseUsSection);
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { t } = useTranslation();
  const openMenu = () => {
      dispatch(actions.toggleStoreSelector());
  };
  return (
    <>
      {data && (
        <div className="why-choose-section-wrapper">
          <div className="why-choose-img-section">
            <img
              src={configs.RESOURCES_BUCKET_URL + "/" + whyChoose.image}
              alt=""
            />
          </div>
          <div className="why-choose-text-section">
            <h5>{t("homePage.whyChooseUsSection.why_choose_us")}</h5>
            <h2>
              {whyChoose.heading?.[i18next.language || "en"] ||
                whyChoose.heading?.["en"]}
            </h2>
            <p>
              {whyChoose.paragraph?.[i18next.language || "en"] ||
                whyChoose.paragraph?.["en"]}
            </p>
            {/* {whyChoose.whyArray.map((item, index) => (
              <Widget key={index} data={item} />
            ))} */}
            <button className="main-button" onClick={() => openMenu()}>
              {t("homePage.whyChooseUsSection.our_menu")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
