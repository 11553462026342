import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  selectRestaurantName,
  selectRestaurantLanguages,
  selectRestaurantData,
  selectRestaurantSurveyPage,
  selectIsAuthenicated,
  selectTable,
  selectCartItemCount,
  selectOrdersInProgressCount,
} from "../../pages/main/selectors";
import { selectSelectedStore } from "../../pages/menuPage/selectors";
import { useSelector, useDispatch } from "react-redux";
import { CgDetailsMore } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { handleScrollOnHomeNavBar } from "../../pages/homePage/components/functions";
import { useHomeSlice } from "../../pages/homePage/actions";
import { useTranslation } from "react-i18next";
import { NavHashLink } from "react-router-hash-link";
import i18next from "i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LANGUAGES } from "../../locals/constants";
import { Fade } from "react-awesome-reveal";
import StoreSelector from "../StoreSelector";
import queryString from "query-string";
import SignOutModal from "../SignOutModal";
import configs from "../../configs";

export default function NavBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { restaurantUrl } = useParams();
  const restaurantName = useSelector(selectRestaurantName);
  const restaurantLanguages = useSelector(selectRestaurantLanguages);
  const restaurantData = useSelector(selectRestaurantData);
  const surveyData = useSelector(selectRestaurantSurveyPage);
  const selectedTable = useSelector(selectTable);
  const selectedStore = useSelector(selectSelectedStore);
  const [verticalMenuOpen, setVerticalMenuOpen] = useState(false);
  const [signoutModalOpen, setSignOutModalOpen] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenicated);
  const orsersCount = useSelector(selectOrdersInProgressCount);
  const cartItems = useSelector(selectCartItemCount);
  const { table } = queryString.parse(window.location.search);
  const [mobileLanguageSelectorOpen, setMobileLanguageSelectorOpen] =
    useState(false);
  const { t, i18n } = useTranslation();
  const storeId = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
  useEffect(() => {
    window.addEventListener("scroll", handleScrollOnHomeNavBar);
    return () => {
      window.removeEventListener("scroll", handleScrollOnHomeNavBar);
    };
  }, []);

  const openMenu = () => {
    dispatch(actions.toggleStoreSelector());
  };
  const checkIfToDisplayNavLinks = () => {
    if (orsersCount > 0 || cartItems > 0) {
      return true;
    }
    if (table) {
      return false;
    }
    return true;
  };

  const logoUrl = () => {
    let url = null;
    if (history.location.pathname === `/${restaurantUrl}`) {
      url = restaurantData?.logoUrl ? restaurantData?.logoUrl : null;
    } else {
      if (selectedStore?.logoUrl) {
        url = selectedStore?.logoUrl;
      } else if (restaurantData?.logoUrl) {
        url = restaurantData?.logoUrl;
      }
    }
    return url;
  };

  return (
    <>
      <div
        // className="navbar-wrapper"
        id="home-navBar"
        className="navbar-wrapper"
      >
        <div className="max-width-wrapper">
          <div
            className="logo"
            onClick={() => {
              checkIfToDisplayNavLinks() && history.push(`/${restaurantUrl}`);
            }}
          >
            {logoUrl() ? (
              <img
                src={configs.RESOURCES_BUCKET_URL + "/" + logoUrl()}
                alt=""
              />
            ) : (
              <h5>{restaurantName}</h5>
            )}
          </div>
          <div id="horizintal-home-navbar">
            <div className="nav-links-wrapper">
              {checkIfToDisplayNavLinks() && (
                <>
                  {restaurantData?.about.enable && (
                    <NavHashLink to={`/${restaurantUrl}#about`}>
                      <p>
                        {t("homePage.navBar.about")}
                        <span />
                      </p>
                    </NavHashLink>
                  )}
                  {window.location.pathname === `/${restaurantUrl}` && (
                    <p onClick={() => openMenu()}>
                      {t("homePage.navBar.menu")}
                      <span />
                    </p>
                  )}
                  {restaurantData?.bestMenu.enable && (
                    <NavHashLink to={`/${restaurantUrl}#featured`}>
                      <p>
                        {t("homePage.navBar.featured")}
                        <span />
                      </p>
                    </NavHashLink>
                  )}
                  <NavHashLink to={`/${restaurantUrl}#contact`}>
                    <p>
                      {t("homePage.navBar.contact")}
                      <span />
                    </p>
                  </NavHashLink>
                </>
              )}
              {surveyData && (
                <NavHashLink to={`/${restaurantUrl}/feedback`}>
                  <p>
                    {t("homePage.navBar.feedback")}
                    <span />
                  </p>
                </NavHashLink>
              )}

              <Dropdown
                isOpen={languageSelectorOpen}
                inNavbar={true}
                toggle={() => setLanguageSelectorOpen(!languageSelectorOpen)}
              >
                <DropdownToggle caret>
                  <p>{LANGUAGES[i18next.language]}</p>
                </DropdownToggle>
                <DropdownMenu>
                  {restaurantLanguages.map((key, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => i18n.changeLanguage(key)}
                    >
                      <p>{LANGUAGES[key]}</p>{" "}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {!selectedTable &&
                (isAuthenticated ? (
                  <div className="signout signin">
                    <p onClick={() => setSignOutModalOpen(!signoutModalOpen)}>
                      {t("menuPage.navBar.sign_out")}
                    </p>
                  </div>
                ) : (
                  window.location.pathname !== `/${restaurantUrl}` && (
                    <div className="signin">
                      <p
                        onClick={() =>
                          history.push(
                            `/${restaurantUrl}/login${
                              storeId ? "?store=" + storeId : ""
                            }`
                          )
                        }
                      >
                        {t("menuPage.navBar.sign_in")}
                      </p>
                    </div>
                  )
                ))}
            </div>
          </div>

          <CgDetailsMore
            className="more-icon"
            onClick={() => setVerticalMenuOpen(!verticalMenuOpen)}
          />
        </div>
      </div>
      {verticalMenuOpen && (
        <div id="vertical-home-navbar">
          <AiOutlineClose
            className="close-icon"
            onClick={() => setVerticalMenuOpen(!verticalMenuOpen)}
          />
          <Fade duration={1000} direction="down">
            <div className="nav-links-wrapper">
              {checkIfToDisplayNavLinks() && (
                <>
                  {restaurantData?.about.enable && (
                    <NavHashLink
                      to={`/${restaurantUrl}#about`}
                      onClick={() => setVerticalMenuOpen(false)}
                    >
                      <p>
                        {t("homePage.navBar.about")}
                        <span />
                      </p>
                    </NavHashLink>
                  )}
                  {window.location.pathname === `/${restaurantUrl}` && (
                    <p onClick={() => openMenu()}>
                      {t("homePage.navBar.menu")}
                      <span />
                    </p>
                  )}
                  {restaurantData?.bestMenu.enable && (
                    <NavHashLink
                      to={`/${restaurantUrl}#featured`}
                      onClick={() => setVerticalMenuOpen(false)}
                    >
                      <p>
                        {t("homePage.navBar.featured")}
                        <span />
                      </p>
                    </NavHashLink>
                  )}
                  <NavHashLink
                    to={`/${restaurantUrl}#contact`}
                    onClick={() => setVerticalMenuOpen(false)}
                  >
                    <p>
                      {t("homePage.navBar.contact")}
                      <span />
                    </p>
                  </NavHashLink>
                </>
              )}
              {surveyData && (
                <NavHashLink
                  to={`/${restaurantUrl}/feedback`}
                  onClick={() => setVerticalMenuOpen(false)}
                >
                  <p>
                    {t("homePage.navBar.feedback")}
                    <span />
                  </p>
                </NavHashLink>
              )}
              <Dropdown
                isOpen={mobileLanguageSelectorOpen}
                toggle={() =>
                  setMobileLanguageSelectorOpen(!mobileLanguageSelectorOpen)
                }
              >
                <DropdownToggle caret>
                  <p>{LANGUAGES[i18next.language]}</p>
                </DropdownToggle>
                <DropdownMenu>
                  {restaurantLanguages.map((key, index) => (
                    <DropdownItem
                      key={`vert-${index}`}
                      onClick={() => {
                        i18n.changeLanguage(key);
                        // setLanguageSelectorOpen(!languageSelectorOpen);
                      }}
                    >
                      <p>{LANGUAGES[key]}</p>{" "}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {!selectedTable &&
                (isAuthenticated ? (
                  <div className="signout signin">
                    <p onClick={() => setSignOutModalOpen(!signoutModalOpen)}>
                      {t("menuPage.navBar.sign_out")}
                    </p>
                  </div>
                ) : (
                  <div className="signin">
                    <p
                      onClick={() => {
                        history.push(
                          `/${restaurantUrl}/login${
                            storeId ? "?store=" + storeId : ""
                          }`
                        );
                        setVerticalMenuOpen(false);
                      }}
                    >
                      {t("menuPage.navBar.sign_in")}
                    </p>
                  </div>
                ))}
            </div>
          </Fade>
        </div>
      )}
      <StoreSelector />
      <SignOutModal
        isOpen={signoutModalOpen}
        toggle={() => setSignOutModalOpen(!signoutModalOpen)}
      />
    </>
  );
}
