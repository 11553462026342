import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import {
  selectRestaurantName,
  selectErrorMessage,
  selectIsAuthenicated,
  selectAuthLoading,
} from '../main/selectors';
import { useSelector } from 'react-redux';
import { MdOutlineClose } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { useMainSlice } from '../main/actions';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import {
  BsFillEyeFill,
  BsFillEyeSlashFill,
  BsPersonFill,
  BsTelephoneFill,
} from 'react-icons/bs';
import { RiLock2Fill } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import ButtonCirculerLoader from '../../components/ButtonCirculerLoader';

export default function Register() {
  const { restaurantUrl } = useParams();
  const dispatch = useDispatch();
  const restaurantName = useSelector(selectRestaurantName);
  const isAuthenticated = useSelector(selectIsAuthenicated);
  const errorMessage = useSelector(selectErrorMessage);
  const logingLoading = useSelector(selectAuthLoading);
  const { store, cart } = queryString.parse(window.location.search);
  const [passwordReveal, setPasswordReveal] = useState(false);
  const history = useHistory();
  const { actions } = useMainSlice();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(actions.clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      if (cart) {
        history.push(
          `/${restaurantUrl}${store ? '/store/' + store + '?cart=true' : ''}`
        );
      } else {
        history.push(`/${restaurantUrl}${store ? '/store/' + store : ''}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  const onSubmit = (data) => {
    dispatch(
      actions.register({ ...data, ...(store ? { storeId: store } : {}) })
    );
  };
  return (
    <div className="register-page-wrapper">
      <MdOutlineClose
        className="close-icon"
        onClick={() =>
          history.push(`/${restaurantUrl}${store ? '/store/' + store : ''}`)
        }
      />
      <h1
        onClick={() =>
          history.push(`/${restaurantUrl}${store ? '/store/' + store : ''}`)
        }
      >
        {restaurantName}
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>{t('signup.lets_get_started')}</h4>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div>
          <p>{t('signup.name')}</p>
          <div
            className={`${
              errors.userName?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <BsPersonFill className="icon" />
            <input
              type="name"
              placeholder="Enter your name"
              {...register('userName', {
                required: true,
              })}
            />
          </div>
        </div>
        <div>
          <p>{t('signup.email')}</p>
          <div
            className={`${
              errors.email?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <MdEmail className="icon" />
            <input
              type="email"
              placeholder="Enter your email"
              {...register('email', {
                required: true,
              })}
            />
          </div>
        </div>

        <div>
          <p>{t('signup.phone_number')}</p>
          <div
            className={`${
              errors.phoneNumber?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <BsTelephoneFill className="icon" />
            <input
              type="phoneNumber"
              placeholder="Enter your phone number"
              {...register('phoneNumber', {
                required: true,
              })}
            />
          </div>
        </div>
        <div>
          <p>{t('signup.password')}</p>
          <div
            className={`${
              errors.password?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <RiLock2Fill className="icon" />
            <input
              placeholder="Enter your password"
              type={passwordReveal ? 'text' : 'password'}
              {...register('password', { required: true })}
            />
            {!passwordReveal ? (
              <BsFillEyeSlashFill
                className="icon password"
                onClick={() => setPasswordReveal(!passwordReveal)}
              />
            ) : (
              <BsFillEyeFill
                className="icon password"
                onClick={() => setPasswordReveal(!passwordReveal)}
              />
            )}
          </div>
        </div>
        <button className="main-button" disabled={logingLoading}>
          {logingLoading ? (
            <>
              {t('signup.sign_up')}
              <ButtonCirculerLoader />{' '}
            </>
          ) : (
            `${t('signup.sign_up')}`
          )}
        </button>
      </form>
      <h6>
        {t('signup.already_have_account')}{' '}
        <Link to={`/${restaurantUrl}/login${store ? '?store=' + store : ''}`}>
          {t('signup.login')}
        </Link>
      </h6>
    </div>
  );
}
