import React, { useEffect, useCallback } from 'react';
import '../styles/featuredSection.scss';
import FoodCard from '../../../components/FoodCard';
import { selectHomeFeaturedFoodSection } from '../../main/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { useHomeSlice } from '../actions';
import { selectFeaturedFoods } from '../selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Carousel from 'react-multi-carousel';

export default function FeaturedSection() {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const featuredSection = useSelector(selectHomeFeaturedFoodSection);
  const featuredFoods = useSelector(selectFeaturedFoods);
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const getFeaturedFoods = useCallback(() => {
    if (!featuredFoods) {
      dispatch(actions.getFeaturedFoods());
    }
  }, [actions, dispatch, featuredFoods]);

  useEffect(() => {
    if (!featuredFoods) {
      getFeaturedFoods();
    }
  }, [featuredFoods, getFeaturedFoods]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 990 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 990, min: 660 },
      items: 3,
    },
    smalltablet: {
      breakpoint: { max: 660, min: 400 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      {featuredFoods && featuredFoods.length > 0 && (
        <div className="home-featured-wrapper">
          <div className="content">
            <h2>
              {featuredSection.heading[i18next.language || 'en'] ||
                featuredSection.heading['en']}
            </h2>
            <hr />
            <p>
              {featuredSection?.paragraph?.[i18next.language || 'en'] ||
                featuredSection?.paragraph?.['en']}
            </p>
            <div>
              <Carousel
                responsive={responsive}
                className="featured-food-carousel"
                autoPlay={true}
                arrows={false}
                infinite={true}
                showDots={true}
              >
                {featuredFoods.map((item, index) => (
                  <FoodCard key={index} data={item} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
