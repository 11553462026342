import { Controller } from "react-hook-form";
import { QUESTION_TYPES } from "../../main/constants";

const TextBoxQuesion = ({ question,name,control,required, ...rest }) => {
  return (
    <>
      <Controller
        control={control}
        rules={{ required: required }}
        name={name}
        render={({ field: { onChange } }) => (
          <input className="qs text-box-qs" onChange={(e)=>onChange({type:QUESTION_TYPES.TEXT_BOX,answer:e.target.value,question:question})} {...rest} />
        )}
      />
    </>
  );
};

export default TextBoxQuesion;
