import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';
import {
  selectRestaurantName,
  selectErrorMessage,
  selectIsAuthenicated,
  selectAuthLoading,
} from '../main/selectors';
import { MdOutlineClose } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { useMainSlice } from '../main/actions';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { RiLock2Fill } from 'react-icons/ri';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import ButtonCirculerLoader from '../../components/ButtonCirculerLoader';

export default function Login() {
  const { restaurantUrl } = useParams();
  const dispatch = useDispatch();
  const restaurantName = useSelector(selectRestaurantName);
  const isAuthenticated = useSelector(selectIsAuthenicated);
  const errorMessage = useSelector(selectErrorMessage);
  const logingLoading = useSelector(selectAuthLoading);
  const [passwordReveal, setPasswordReveal] = useState(false);
  const { store, cart, resetpassword } = queryString.parse(
    window.location.search
  );
  const { actions } = useMainSlice();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(actions.clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      if (cart && store) {
        history.push(`/${restaurantUrl}/store/${store}?cart=true`);
      } else if (!store) {
        history.push(`/${restaurantUrl}`);
      } else {
        history.push(`/${restaurantUrl}/store/${store}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  const onSubmit = (data) => {
    dispatch(actions.login({ ...data, storeId: store }));
  };
  return (
    <div className="login-page-wrapper">
      <MdOutlineClose
        className="close-icon"
        onClick={() =>
          history.push(`/${restaurantUrl}${store ? '/store/' + store : ''}`)
        }
      />
      <h1
        onClick={() =>
          history.push(`/${restaurantUrl}${store ? '/store/' + store : ''}`)
        }
      >
        {restaurantName}
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>{t('login.welcome_back')}</h4>
        {cart && (
          <p className="success-message">{t('login.just_step_ahead')}</p>
        )}
        {resetpassword && (
          <p className="success-message">{t('login.success_reset_password')}</p>
        )}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div>
          <p>{t('login.email')}</p>
          <div
            className={`${
              errors.email?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <MdEmail className="icon" />
            <input
              type="email"
              placeholder={t('login.email')}
              {...register('email', {
                required: true,
                pattern: RegExp(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ),
              })}
            />
          </div>
        </div>
        <div>
          <p>{t('login.password')}</p>
          <div
            className={`${
              errors.password?.type === 'required' ? 'error ' : ' '
            } input-wrapper`}
          >
            <RiLock2Fill className="icon" />
            <input
              placeholder={t('login.password')}
              type={passwordReveal ? 'text' : 'password'}
              {...register('password', { required: true })}
            />
            {!passwordReveal ? (
              <BsFillEyeSlashFill
                className="icon password"
                onClick={() => setPasswordReveal(!passwordReveal)}
              />
            ) : (
              <BsFillEyeFill
                className="icon password"
                onClick={() => setPasswordReveal(!passwordReveal)}
              />
            )}
          </div>
        </div>
        <p>
          <Link to={`/${restaurantUrl}/reset-password?store=${store}`}>
            {t('login.forgot_password')}{' '}
          </Link>
        </p>
        <button className="main-button" disabled={logingLoading}>
          {logingLoading ? (
            <>
              {t('login.login')}
              <ButtonCirculerLoader />{' '}
            </>
          ) : (
            `${t('login.login')}`
          )}
        </button>
      </form>
      <p>
        {t('login.new_here')}{' '}
        <Link
          to={`/${restaurantUrl}/register${store ? '?store=' + store : ''}`}
        >
          {t('login.create_account')}
        </Link>
      </p>
    </div>
  );
}
