import {
  CART_ITEM_PROPERTIES,
  TAX_TYPES,
  PROMOTION_TYPES,
  PROMOTION_DISCOUNT_TYPES,
} from './constants';

/* eslint-disable array-callback-return */
export const rearrangeBoughtItemsAndTotal = (previousItems, newItem) => {
  try {
    let items = { ...previousItems };
    let total = 0.0;
    let sub_total = 0.0;
    let discount = 0.0;
    let tax_included = 0.0;
    let tax_not_included = 0.0;

    const access_modifiers = (key) => {
      let _modifier_items = [];
      let mod_total = 0.0;
      let mod_sub_total = 0.0;
      let mod_discount = 0.0;
      // let mod_tax = 0.0;
      let mod_tax_included = 0.0;
      let mod_tax_not_included = 0.0;

      previousItems[CART_ITEM_PROPERTIES.MODIFIERS]?.items.map((item) => {
        if (item._id !== newItem[key]._id) {
          _modifier_items.push(item);
          mod_total = mod_total + item.total;
          mod_discount = mod_discount + item.discount;
          mod_sub_total = mod_sub_total + item.sub_total;
          mod_tax_included = mod_tax_included + item.tax_included;
          mod_tax_not_included = mod_tax_not_included + item.tax_not_included;
        }
      });
      if (newItem[key].count > 0) {
        _modifier_items.push(newItem[key]);
        mod_total = mod_total + newItem[key].total;
        mod_discount = mod_discount + newItem[key].discount;
        mod_sub_total = mod_sub_total + newItem[key].sub_total;
        mod_tax_included = mod_tax_included + newItem[key].tax_included;
        mod_tax_not_included =
          mod_tax_not_included + newItem[key].tax_not_included;
      }

      items = {
        ...items,
        [CART_ITEM_PROPERTIES.MODIFIERS]: {
          items: _modifier_items,
          discount: mod_discount,
          tax_included: mod_tax_included,
          tax_not_included: mod_tax_not_included,
          total: mod_total,
          sub_total: mod_sub_total,
        },
      };
      
      const food_count = items[CART_ITEM_PROPERTIES.FOOD].count;
      discount = discount + mod_discount;
      tax_not_included = tax_not_included + mod_tax_not_included;
      tax_included = tax_included + mod_tax_included;
      total = total + mod_total*food_count;
      sub_total = sub_total + mod_sub_total*food_count ;
    };
    const calculateModifiersSubTotalWithFoodCount = ({
      modifierItems,
      foodCount,
    }) => {
      let total = 0.0;
      try {
        modifierItems.map((item) => {
          total = total + item.count * foodCount * item.price;
        });
        return total;
    
      } catch (error) {
        console.log(error);
        return total;
      }
    };
    const add_modifier_totals = ({ food_count = 1 }) => {
      if (previousItems.modifiers?.items.length > 0) {
        discount = discount + previousItems.modifiers.discount;
        total =
          total +
          calculateModifiersSubTotalWithFoodCount({
            modifierItems: previousItems.modifiers.items,
            foodCount: food_count,
          });
        // sub_total = sub_total + previousItems.modifiers.sub_total;
        sub_total =
          sub_total +
          calculateModifiersSubTotalWithFoodCount({
            modifierItems: previousItems.modifiers.items,
            foodCount: food_count,
          });
        tax_not_included =
          tax_not_included + previousItems.modifiers.tax_not_included;
        tax_included = tax_included + previousItems.modifiers.tax_included;
      }
    };
    const access_food = (food) => {
      discount = discount + food.discount;
      total = total + food.total;
      sub_total = sub_total + food.sub_total;
      tax_not_included = tax_not_included + food.tax_not_included;
      tax_included = tax_included + food.tax_included;
    };

    Object.keys(newItem).map((key) => {
      switch (key) {
        case CART_ITEM_PROPERTIES.FOOD:
          access_food(newItem[key]);
          add_modifier_totals({ food_count: newItem[key].count });
          items[key] = newItem[key];
          break;
        case CART_ITEM_PROPERTIES.MODIFIERS:
          access_modifiers(key);
          access_food(items[CART_ITEM_PROPERTIES.FOOD]);
          // add_modifier_totals({ food_count: items[CART_ITEM_PROPERTIES.FOOD].count });
          break;
        default:
          break;
      }
    });
    const output = {
      ...items,
      total,
      discount,
      tax_included,
      tax_not_included,
      sub_total,
    };
    return output;
  } catch (error) {
    window.alert(error);
    return previousItems;
  }
};
export const calculateDiscountOnFoodItem = ({
  food,
  sub_total,
  promotions,
}) => {
  // This function should calculate the promotional discount on a food item

  //get discount promotions on selectd item
  const availbleItemPromotions = promotions
    .filter(
      (promotion) =>
        promotion.foodIds.includes(food?._id) &&
        promotion.type === PROMOTION_TYPES.ITEM
    )
    .map((promotion) => {
      const externalPosDiscountDetails = promotion?.externalPosDiscountDetails
        ? promotion.externalPosDiscountDetails
        : null;

      return {
        id: promotion._id,
        name: promotion.name,
        type: promotion.promotionType,
        discount: promotion.isAddOns
          ? Number.parseFloat(
              (sub_total * promotion.discountValue) / 100
            ).toFixed(2)
          : Number.parseFloat(
              (sub_total * promotion.discountValue) / 100
            ).toFixed(2),
        externalPosDiscountDetails,
      };
    });

  let itemTotalDiscount = availbleItemPromotions.reduce((prev, current) => {
    return prev + +current.discount;
  }, 0);
  return itemTotalDiscount;
};

export const calculateDiscountOnCart = ({
  data,
  promotions,
  existingCartData,
}) => {
  //get discount promotions on cart
  // let discount = 0.0;
  const total = data.sub_total + existingCartData.sub_total;
  const itemPromotions = promotions.filter(
    (promotion) =>
      promotion.type === PROMOTION_TYPES.CART &&
      total >= promotion.minOrderAmount
  );
  return itemPromotions.map((promotion) => {
    return {
      id: promotion._id,
      name: promotion.name,
      discount:
        promotion.discountType === PROMOTION_DISCOUNT_TYPES.PERCENTAGE
          ? Math.round(total * promotion.discountValue) / 100
          : promotion.discountValue,
    };
  });

  // itemPromotions.forEach((promotion) => {
  //   if(promotion.discountType === PROMOTION_DISCOUNT_TYPES.PERCENTAGE){
  //     discount = discount + Math.round(total  * promotion.discountValue) / 100;
  //   }else{
  //     discount = discount +promotion.discountValue;
  //   }
  // });
  // return {
  //   totalDiscount:discount,
  //   promotions: itemPromotions.map((promotion) => {
  //     return { id:promotion._id,name: promotion.name, discount:promotion.discountType === PROMOTION_DISCOUNT_TYPES.PERCENTAGE ? Math.round(total  * promotion.discountValue) / 100  : promotion.discountValue };
  //   }),
  // };
};
export const calculateTaxOnFoodItem = ({ taxes, price, dine_mode }) => {
  let tax_included = 0.0;
  let tax_not_included = 0.0;
  try {
    taxes.map((tax_item) => {
      if (tax_item.type === TAX_TYPES.not_include_to_price) {
        tax_not_included =
          tax_not_included + Math.round(price * tax_item[dine_mode]) / 100;
      } else if (tax_item.type === TAX_TYPES.include_to_price) {
        tax_included =
          tax_included +
          Math.round(
            (price * tax_item[dine_mode] * 100) / (100 + tax_item[dine_mode])
          ) /
            100;
      }
    });
  } catch (error) {
    console.log(error);
  } finally {
    return { tax_included, tax_not_included };
  }
};
export const calculateTotalOnFoodItem = ({ food, count, price }) => {
  try {
    const data = (price || price === 0) ? (price * count) : food.price;
    return data
  } catch (error) {
    return 0.0;
  }
};

export const getPromotionsDiscount = ({ promotions, total, data }) => {
  try {
    let discount = 0.0;
    return discount;
  } catch (error) {
    console.log(error);
    return 0.0;
  }
};

// Cart items Format
// cart = [
//   {
//     food:{
//       id:
//       name:
//       imageUrls:[]
//       count:
//       price:
//       promotions: []
//       taxes: []
//       discount:
//       tax_included:
//       tax_not_included:
//       total:
//       sub_total:
//     },
//     modifiers:{
//      items: [
//       {
//         groupName:
//         name:
//         count:
//         price:
//         unit:
//         promotions: []
//         taxes: []
//         discount:
//         tax_included:
//         tax_not_included:
//         total:
//         sub_total:
//       },
//       ...
//      ],
//     discount:
//     tax_included:
//     tax_not_included:
//     total:
//     sub_total:
//     }
//     instructions:
//     discount:
//     tax_included:
//     tax_not_included:
//     total:
//     sub_total:
//   },
//   ...
// ]

// Order Format
// order = {
//    items: [
//     {
//       food:{
//         id:
//         name:
//         imageUrls:[]
//         count:
//         price:
//         promotions: []
//         taxes: []
//         discount:
//         tax:
//         total:
//         sub_total:
//       },
//       modifiers:[
//         {
//           groupName:
//           name:
//           count:
//           price:
//           unit:
//           promotions: []
//           taxes: []
//           discount:
//           tax:
//           total:
//         },
//         ...
//       ],
//       instructions:,
//       discount:
//       tax:
//       total:
//       sub_total:
//     },
//     ...
//   ],
//   promotions: [
//     {
//       type:
//       value:
//       name:
//     },
//     ...
//     ],
//   customerId:,
//   table:{
//     name,
//     uid
//   },
//   storeId:,
//   uid:,
//   restaurantId:,
//   currency:,
//   tip:,
//   discount:
//   tax:,
//   total:,
//   sub_total:,
//   payment_mehord:
//   status:,
//   payment_reference:
// }
