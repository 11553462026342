import React from "react";
import "./styles.scss";

import LoadingImage from "../../images/loading.gif";

export default function Loading() {
  return (
    <div className="loading">
      <img src={LoadingImage} alt="" />
    </div>
  );
}
