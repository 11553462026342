export const API = {
  GET_RESTURANT_DATA: {
    path: "/customer-app/restaurant",
    method: "POST",
  },
  GET_STORE_DATA: {
    path: "/customer-app/stores/:storeId",
    method: "GET",
  },
  REGISTER: {
    path: "/customer-app/signup",
    method: "POST",
  },
  LOGIN: {
    path: "/customer-app/login",
    method: "POST",
  },
  SAVE_ORDER: {
    path: "/customer-app/order",
    method: "POST",
  },
  EDIT_ORDER: {
    path: "/customer-app/order",
    method: "PUT",
  },
  FETCH_ORDERS: {
    path: "/customer-app/fetch-orders",
    method: "POST",
  },
  SEND_RESET_PASSWORD_LINK: {
    path: "/customer-app/customer/request-password-reset/:email",
    method: "GET",
  },
  RESET_PASSWORD: {
    path: "/customer-app/reset-password",
    method: "PUT",
  },
  GET_CURRENT_PROMOTIONS: {
    path: "/customer-app/current-promotions/:restaurantId/:storeId/:orderType/:currentTime",
    method: "GET",
  },
  GET_CURRENT_USER: {
    path: "/customers/:id",
    method: "GET",
  },
  GET_SUBSCTIPTION: {
    path: "/customer-app/payments/subscription/:adminId",
    method: "GET",
  },
  GET_STRIPE_PAYMENT_PAGE: {
    path: "/customer-app/payments/stripe",
    method: "POST",
  },
  GET_PAYMENT_INTENT_DETAILS: {
    path: "/customer-app/payments/payment-intent",
    method: "POST",
  },
  GET_FONTS: {
    path: "/customer-app/fonts/:id",
    method: "GET",
  },
  GET_HOT_ACTIONS: {
    path: "/customer-app/hot-actions/:restaurantId/:storeId",
    method: "GET",
  },
};
// :
export const QUESTION_TYPES = {
  TEXT_BOX: "TEXT_BOX",
  STAR_RATING: "STAR_RATING",
  YES_NO: "YES_NO",
  SMILEY: "SMILEY",
};
export const FONTS_SECTIONS = {
  hero_section: "hero_section",
  about_section: "about_section",
  most_popular_foods_section: "most_popular_foods_section",
  why_choose_us_section: "why_choose_us_section",
  contact_us_section: "contact_us_section",
  newsletter_section: "newsletter_section",
  modals: "modals",
  food_card: "food_card",
  nav_bar: "nav_bar",
  category_bar: "category_bar",
  auth: "auth",
  hot_action_requests: "hot_action_requests",
};
export const COLORS_SECTIONS = {
  background_color: "background_color",
  primary_text_color: "primary_text_color",
  primary_button_color: "primary_button_color",
  secondary_text_color: "secondary_text_color",
  nav_bar_color: "nav_bar_color",
  button_text_color: "button_text_color",
};
export const PROMOTION_TYPES = {
  CART: "CART",
  ITEM: "ITEM",
};
export const PROMOTION_DISCOUNT_TYPES = {
  PERCENTAGE: "PERCENTAGE",
  AMOUNT: "AMOUNT",
};
