import React, { useEffect, useState } from 'react';
import './styles.scss';

const ModifierCard = ({
  count,
  setCount,
  name,
  price,
  unit,
  isMultipleItems,
  currency,
  groupCount,
  setGroupCount,
}) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (count === 0) {
      setClicked(false);
    }
  }, [count]);

  return (
    <div
      className={`modifier-card-wrapper ${clicked ? ' selected' : ''}`}
      onClick={() => {
        setClicked(!clicked);
        if (!clicked) {
          setCount(count + 1);
          setGroupCount(groupCount + 1);
        } else {
          if (count > 0) {
            setCount(0);
          }
          if (groupCount > 0) {
            setGroupCount(groupCount - count);
          }
        }
      }}
    >
      <div className="action-btn">
        <input type="checkbox" checked={clicked} readOnly />
      </div>
      <p className="name">{name}</p>
      <div className="price">
        <p>
          {`+ ${currency}`} {price} {unit && `/${unit}`}
        </p>
      </div>

      {isMultipleItems && count > 0 && (
        <div className="button-container">
          <button
            onClick={(event) => {
              event.stopPropagation();
              if (count > 0) {
                setCount(count - 1);
                setGroupCount(groupCount - 1);
              }
            }}
          >
            -
          </button>
          <p>{count}</p>
          <button
            onClick={(event) => {
              event.stopPropagation();
              setCount(count + 1);
              setGroupCount(groupCount + 1);
            }}
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

export default ModifierCard;
