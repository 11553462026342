/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Footer from "../../components/Footer";
import HomePage from "../homePage";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import "./styles.scss";
import MenuPage from "../menuPage";
import Login from "../loginpage";
import Register from "../register";
import { useMainSlice } from "./actions";
import {
  selectRestaurantData,
  selectCurrentUserId,
  selectOrdersInProgressCount,
  selectSubscriptionStatus,
  selectOrderSavingSucceeded,
  selectCartItemCount,
  selectFonts,
  selectHotActions,
} from "./selectors";
import { selectSelectedStore } from "../menuPage/selectors";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/Loading";
import ResetPassword from "../resetPassword";
import { validateToken } from "../../services/authhandler";
import { connectToSocketRoom } from "../../socket/socketCtrl";
import OrdersCountModal from "../../components/OrdersCountModal";
import RestrictedPage from "../RestrictedPage";
import FeedbackPage from "../feedbackPage";
import NavBar from "../../components/NavBar";
import queryString from "query-string";
import CartModal from "../../components/CheckoutModal/components/CartModal";
import HotActionsModal from "../../components/HotActionsModal";
import { TiShoppingCart } from "react-icons/ti";
import { BsChatDots } from "react-icons/bs";
import { setColors, setFonts } from "./functions";
import { useTranslation } from "react-i18next";
import configs from "../../configs";
import { loadStripe } from "@stripe/stripe-js";

export default function Main() {
  const dispatch = useDispatch();
  const restaurantData = useSelector(selectRestaurantData);
  const store = useSelector(selectSelectedStore);

  const [showCartModal, setShowCartModal] = useState(false);
  const [showHotActionsModal, setShowHotActionsModal] = useState(false);

  const currentUserId = useSelector(selectCurrentUserId);
  const isSubscriptionActive = useSelector(selectSubscriptionStatus);
  const fonts = useSelector(selectFonts);
  const orderCountinProgress = useSelector(selectOrdersInProgressCount);
  const { restaurantUrl } = useParams();
  const orderSavingSucceeded = useSelector(selectOrderSavingSucceeded);
  const hotActions = useSelector(selectHotActions);

  const { cart, table } = queryString.parse(window.location.search);
  const { actions } = useMainSlice();
  const cartItemCount = useSelector(selectCartItemCount);
  const history = useHistory();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!restaurantData) {
      dispatch(actions.getRestaurantData({ restaurantUrl, table }));
      connectToSocketRoom({ room: restaurantUrl });
    }
  }, [actions, dispatch, restaurantData, restaurantUrl]);

  useEffect(() => {
    const validated = validateToken();
    if (validated) {
      if (currentUserId) {
        if (currentUserId !== validated) {
          dispatch(actions.clearCurrentUser());
          dispatch(actions.getCurrentUser({ id: validated }));
        } else {
          dispatch(actions.setAuthenticatedStatus(true));
        }
      } else {
        dispatch(actions.setAuthenticatedStatus(true));
        dispatch(actions.getCurrentUser({ id: validated }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, currentUserId]);

  useEffect(() => {
    if (restaurantData) {
      if (restaurantData.paymentIntegrations?.stripe?.accountId) {
        window.stripePromise = loadStripe(configs.STRIPE_PK, {
          stripeAccount: restaurantData.paymentIntegrations.stripe.accountId,
        });
      }
      dispatch(
        actions.getSubscriptionStatus({ adminId: restaurantData.createdBy }),
      );
      if (restaurantData.fontsId) {
        dispatch(actions.getFonts({ id: restaurantData.fontsId }));
      }
      i18n.changeLanguage(restaurantData.default_language);
      setColors(restaurantData.colors);
    }
  }, [restaurantData]);

  useEffect(() => {
    if (fonts) {
      setFonts(fonts);
    }
  }, [fonts]);

  useEffect(() => {
    if (cart) {
      setShowCartModal(true);
    }
  }, [cart]);

  useEffect(() => {
    // Only send the user's location data if it is a table scan
    if (table) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const data = {
              coordinates: {
                accuracy: position?.coords?.accuracy,
                latitude: position?.coords?.latitude,
                longitude: position?.coords?.longitude,
              },
            };
            dispatch(actions.setLocationDetailsSucceeded(data));
          },
          () => {
            dispatch(
              actions.setLocationDetailsFailed(
                "Something went wrong when receiving location data.",
              ),
            );
          },
        );
      } else {
        dispatch(
          actions.setLocationDetailsFailed("Geolocation not available."),
        );
      }
    }
  }, [table]);

  useEffect(() => {
    if (restaurantData && store) {
      dispatch(actions.receiveHotActions());
    }
  }, [restaurantData, store]);

  const toggleCartlModal = () => {
    if (orderSavingSucceeded) {
      dispatch(actions.clearCart());
    }
    const store = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
    if (store) {
      routeTomenu(store);
    } else {
      dispatch(actions.clearCart());
    }
    setShowCartModal((_show) => !_show);
  };

  const routeTomenu = (store) => {
    if (history.location.pathname === `/${restaurantUrl}`) {
      localStorage.setItem(`store-${configs.APP_DOMAIN}`, store);
      history.push(`/${restaurantUrl}/store/${store}`);
      dispatch(actions.setSelectedStoreId(store));
    }
  };

  const handleCloseHotActionsModal = () => {
    setShowHotActionsModal(!showHotActionsModal);
  };

  return (
    <>
      {restaurantData && isSubscriptionActive ? (
        <div className="main-page-wrapper">
          <NavBar />
          <Switch>
            <Route
              exact
              path="/:restaurantUrl/store/:storeId"
              component={MenuPage}
            />
            <Route
              exact
              path="/:restaurantUrl/feedback"
              component={FeedbackPage}
            />
            <Route exact path="/:restaurantUrl" component={HomePage} />
            <Route exact path="/:restaurantUrl/login" component={Login} />
            <Route exact path="/:restaurantUrl/register" component={Register} />
            <Route
              exact
              path="/:restaurantUrl/reset-password"
              component={ResetPassword}
            />
          </Switch>
          <Footer />
          {orderCountinProgress > 0 && <OrdersCountModal />}
        </div>
      ) : isSubscriptionActive ? (
        <Loading />
      ) : (
        <RestrictedPage />
      )}
      <CartModal isOpen={showCartModal} toggle={toggleCartlModal} />
      {cartItemCount > 0 && (
        <div className="cart-wrapper">
          <button className="cartIcon" onClick={toggleCartlModal}>
            <TiShoppingCart className="cart" />
            <div className="dot">{cartItemCount}</div>
          </button>
        </div>
      )}

      {showHotActionsModal && hotActions.length ? (
        <HotActionsModal
          isOpen={showHotActionsModal}
          onClose={handleCloseHotActionsModal}
        />
      ) : null}

      {table && hotActions.length ? (
        <div
          className={`hot-action-wrapper ${
            restaurantData ? "active" : "disable"
          }`}
        >
          <button
            className="hot-action-icon"
            onClick={() => setShowHotActionsModal(!showHotActionsModal)}
          >
            <BsChatDots size={25} /> {t("hot_actions.request")}
          </button>
        </div>
      ) : null}
      <ToastContainer />
    </>
  );
}
