import React, { useState } from "react";
import "./styles.scss";
const IconGroup = ({ icons, onChange }) => {
  const [clickedId, setClickedId] = useState(-1);
  const handleClick = (event, id,name) => {
    setClickedId(id);
    onChange(name);
  };

  return (
    <div className="icon-group-container">
      {icons.map((item, index) => {
        return (
          <span
            key={index}
            onClick={(event) => handleClick(event,index,item.name)}
            className={index === clickedId ? "active-icon" : ""}
          >
            {item.icon}
          </span>
        );
      })}
    </div>
  );
};

export default IconGroup;
