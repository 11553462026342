import { PROMOTION_TYPES, PROMOTION_DISCOUNT_TYPES } from "../../constants";
export const addToCartDefault = (state, payload) => {
  const cartData = JSON.parse(JSON.stringify(state.cartData));
  const promotions = JSON.parse(JSON.stringify(state.promotions));
  let discount = 0.0;
  let sub_total = 0.0;
  let total = 0.0;
  let tip = 0.0;
  let tax_included = 0.0;
  let tax_not_included = 0.0;

  //get available promotions for item
  const availbleItemPromotions = promotions
    .filter(
      (promotion) =>
        promotion.foodIds.includes(payload?.food?._id) &&
        promotion.type === PROMOTION_TYPES.ITEM
    )
    .map((promotion) => {
      const externalPosDiscountDetails = promotion?.externalPosDiscountDetails
        ? promotion.externalPosDiscountDetails
        : null;

      return {
        id: promotion._id,
        name: promotion.name,
        type: promotion.promotionType,
        discount: promotion.isAddOns
          ? Number.parseFloat(
              (payload?.total * promotion.discountValue) / 100
            ).toFixed(2)
          : Number.parseFloat(
              (payload?.food?.total * promotion.discountValue) / 100
            ).toFixed(2),
        externalPosDiscountDetails,
      };
    });

  let itemTotalDiscount = availbleItemPromotions.reduce((prev, current) => {
    return prev + +current.discount;
  }, 0);

  const newItem = {
    ...payload,
    promotions: availbleItemPromotions,
    discount: itemTotalDiscount,
  };
  let items = [...cartData.items, newItem];
  items.forEach((_item) => {
    discount = discount + _item.discount;
    sub_total = sub_total + _item.sub_total;
    total = total + _item.total;
    tax_included = tax_included + _item.tax_included;
    tax_not_included = tax_not_included + _item.tax_not_included;
  });
  //availble promotions for cart
  let availablePromotions = promotions
    .filter(
      (promotion) =>
        promotion.type === PROMOTION_TYPES.CART &&
        total >= promotion.minOrderAmount
    )
    .map((promotion) => {
      const externalPosDiscountDetails = promotion?.externalPosDiscountDetails
        ? promotion.externalPosDiscountDetails
        : null;

      return {
        id: promotion._id,
        name: promotion.name,
        discount:
          promotion.discountType === PROMOTION_DISCOUNT_TYPES.PERCENTAGE
            ? Number.parseFloat(
                (total * promotion.discountValue) / 100
              ).toFixed(2)
            : promotion.discountValue,
        minOrderAmount: promotion.minOrderAmount,
        type: promotion.promotionType,
        externalPosDiscountDetails,
      };
    });

  let cartTotalDiscount = availablePromotions.reduce((prev, current) => {
    return prev + +current.discount;
  }, 0);

  let totalDiscount = discount + cartTotalDiscount;

  return {
    cartItemCount: items.length,
    items,
    discount: Number.parseFloat(totalDiscount).toFixed(2),
    sub_total: Number.parseFloat(sub_total).toFixed(2),
    total: Number.parseFloat(total - totalDiscount).toFixed(2),
    tip,
    promotions: availablePromotions,
    tax_included,
    tax_not_included,
  };
};

export const removeCartDefault = (state, payload) => {
    const cartData = JSON.parse(JSON.stringify(state.cartData));
    const promotions = JSON.parse(JSON.stringify(state.promotions));
    
    let items = [...cartData.items];
    items.splice(payload, 1);
    let discount = 0.0;
    let sub_total = 0.0;
    let total = 0.0;
    let tip = 0.0;
    let tax_included = 0.0;
    let tax_not_included = 0.0;
    items.forEach((_item) => {
      discount = discount + _item.discount;
      sub_total = sub_total + _item.sub_total;
      total = total + _item.total;
      tax_included = tax_included + _item.tax_included;
      tax_not_included = tax_not_included + _item.tax_not_included;
    });
    //availble promotions for cart
    let availablePromotions = promotions
      .filter(
        (promotion) =>
          promotion.type === PROMOTION_TYPES.CART &&
          sub_total >= promotion.minOrderAmount
      )
      .map((promotion) => {
        return {
          id: promotion._id,
          name: promotion.name,
          type: promotion.promotionType,
          discount:
            promotion.discountType === PROMOTION_DISCOUNT_TYPES.PERCENTAGE
              ? Number.parseFloat(
                  (sub_total * promotion.discountValue) / 100
                ).toFixed(2)
              : promotion.discountValue,
          minOrderAmount: promotion.minOrderAmount,
        };
      });
    let cartTotalDiscount = availablePromotions.reduce((prev, current) => {
      return prev + +current.discount;
    }, 0);

    let totalDiscount = discount + cartTotalDiscount;
  
    return {
      cartItemCount: items.length,
      items,
      discount: Number.parseFloat(totalDiscount).toFixed(2),
      sub_total: Number.parseFloat(sub_total).toFixed(2),
      total: Number.parseFloat(total - totalDiscount).toFixed(2),
      tip,
      promotions: availablePromotions,
      tax_included,
      tax_not_included,
    };
  };
