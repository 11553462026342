import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  selectRestaurantName,
  selectResetPasswordLinkSent,
  selectAuthLoading,
  selectErrorMessage,
} from '../../main/selectors';
import { useSelector } from 'react-redux';
import { MdOutlineClose } from 'react-icons/md';
import queryString from 'query-string';
import { useMainSlice } from '../../main/actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ValidationError from '../../../components/ValidationError';
import { BsFillShieldLockFill } from 'react-icons/bs';
import ButtonLoader from '../../../components/ButtonLoader';
import { useTranslation } from 'react-i18next';

export default function InputEmail() {
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scroll(0, 0);
    dispatch(actions.clearErrorMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const { restaurantUrl } = useParams();
  const restaurantName = useSelector(selectRestaurantName);
  const sentResetPasswordLink = useSelector(selectResetPasswordLinkSent);
  const errorMessage = useSelector(selectErrorMessage);
  const loading = useSelector(selectAuthLoading);
  const { store } = queryString.parse(window.location.search);
  const { t } = useTranslation();
  const onSubmit = (data) => {
    console.log(data);
    dispatch(actions.sendResetPasswordLink(data));
  };
  return (
    <div className="reset-password-page-wrapper">
      <MdOutlineClose
        className="close-icon"
        onClick={() => history.push(`/${restaurantUrl}/store/${store}`)}
      />
      <BsFillShieldLockFill className="lock-icon" />
      <h1 onClick={() => history.push(`/${restaurantUrl}/store/${store}`)}>
        {restaurantName}
      </h1>

      {!sentResetPasswordLink ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4>{t('forgot_password.forgot_password')}</h4>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div>
            <p>{t('forgot_password.enter_email')}</p>
            <p className="label">{t('forgot_password.email')}</p>
            <input
              type="email"
              placeholder="example@gmail.com"
              {...register('email', { required: true })}
            />
            {errors.email?.type === 'required' && (
              <ValidationError
                text={`${t('forgot_password.email')} ${t(
                  'forgot_password.is_required'
                )}`}
              />
            )}
          </div>
          <button className="main-button" disabled={loading}>
            {loading ? <ButtonLoader /> : `${t('forgot_password.email')}`}
          </button>
        </form>
      ) : (
        <div className="sent-">
          <p>{t('forgot_password.sent_email')}</p>
        </div>
      )}
    </div>
  );
}
