import React from 'react';
import './styles.scss';
import InputEmail from './components/InputEmail';
import InputPassword from './components/InputPassword';
import queryString from 'query-string';

export default function ResetPassword() {
  const { verified } = queryString.parse(window.location.search);
  return (
    <>
      {!verified && <InputEmail />}
      {verified && <InputPassword />}
    </>
  );
}
