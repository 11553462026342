import React, { useEffect } from "react";
import { FiMapPin, FiPhoneCall } from "react-icons/fi";
import { FaRegPaperPlane, FaRegClock } from "react-icons/fa";
import {
  selectContactDetails,
  selectRestaurantId,
  selectNewsletterInfo,
} from "../../main/selectors";
import "../styles/contactUsSection.scss";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectSavingNewsletter, selectNewsletterSaved } from "../selectors";
import { useForm } from "react-hook-form";
import { homeActions } from "../actions";
import ButtonLoader from "../../../components/ButtonLoader";
import { TiTick } from "react-icons/ti";
import { PhoneNumberInput } from "../../../components/Inputs";

export default function ContactUsSection() {
  const contactDetails = useSelector(selectContactDetails);
  const restaurantId = useSelector(selectRestaurantId);
  const newsletterInfo = useSelector(selectNewsletterInfo);
  const savingNewsletter = useSelector(selectSavingNewsletter);
  const newsletterSaved = useSelector(selectNewsletterSaved);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const { t } = useTranslation();

  const onSubmit = (data) => {
    dispatch(homeActions.saveNewsletter({ restaurantId, ...data }));
  };

  useEffect(() => {
    if (newsletterSaved) {
      reset();
      setTimeout(() => {
        dispatch(homeActions.clearNewsletterSavedStatus());
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletterSaved]);

  return (
    <div className="contactUs-wrapper">
      <div
        className={
          newsletterInfo?.enable ? "contents" : "contents display-in-center"
        }
      >
        <div>
          <h5>{t("homePage.contactUsSection.contact_us")}</h5>
          <h2>{t("homePage.contactUsSection.get_closer")}</h2>
          <p>{t("homePage.contactUsSection.feel_free_para")}</p>
          <div>
            <div>
              {contactDetails.email && (
                <div>
                  <FaRegPaperPlane className="icon" />
                  <div>
                    <h4>{t("homePage.contactUsSection.email")}</h4>
                    <p>{contactDetails.email}</p>
                  </div>
                </div>
              )}
            </div>
            <div>
              {contactDetails.phoneNumber && (
                <div>
                  <FiPhoneCall className="icon" />
                  <div>
                    <h4>{t("homePage.contactUsSection.phone")}</h4>
                    <p>{contactDetails.phoneNumber}</p>
                  </div>
                </div>
              )}
            </div>
            <div>
              {contactDetails.address && (
                <div>
                  <FiMapPin className="icon" />
                  <div>
                    <h4>{t("homePage.contactUsSection.location")}</h4>
                    <p>{contactDetails.address}</p>
                  </div>
                </div>
              )}
            </div>
            {contactDetails.openHours && (
              <div>
                <div>
                  <FaRegClock className="icon" />
                  <div>
                    <h4>{t("homePage.contactUsSection.open_hour")}</h4>
                    <p>{contactDetails.openHours}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {newsletterInfo?.enable ? (
          <div className="email-sub-container">
            <div className="title">
              <h4>{t("homePage.contactUsSection.email_main_titile")}</h4>
              <p>{t("homePage.contactUsSection.email_sub_titile")}</p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row-section">
                <div className="col-section">
                  <input
                    className={`${
                      errors.firstName?.type === "required" ? "error" : ""
                    }`}
                    type="name"
                    {...register("firstName", { required: true })}
                    placeholder={t("homePage.contactUsSection.first_name")}
                  />
                </div>
                <div className="col-section">
                  <input
                    className={`${
                      errors.lastName?.type === "required" ? "error" : ""
                    }`}
                    type="name"
                    {...register("lastName", { required: true })}
                    placeholder={t("homePage.contactUsSection.last_name")}
                  />
                </div>
                <div className="col-section">
                  <input
                    className={`${
                      errors.email?.type === "required" ? "error" : ""
                    }`}
                    type="email"
                    {...register("email", { required: true })}
                    placeholder={t(
                      "homePage.contactUsSection.email@domain.com"
                    )}
                  />
                </div>
                <div className="col-section">
                  <PhoneNumberInput control={control} name={"contactNo"} />
                </div>
              </div>
              <div className="btn-container">
                <button className="main-button" disabled={savingNewsletter}>
                  {newsletterSaved ? (
                    <TiTick className="icon" />
                  ) : savingNewsletter ? (
                    <ButtonLoader />
                  ) : (
                    `${t("homePage.contactUsSection.submit")}`
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </div>
  );
}
