/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectStripePaymentIntentSecret } from '../../../pages/main/selectors';
import {
  selectCartData,
  selectRestaurantCurrency,
  selectStripeAccountId,
  // selectTipAmount,
  selectErrorMessage,
} from '../../../pages/main/selectors';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import '../styles/stripeCheckoutStyles.scss';
// import configs from '../../../configs';
import ButtonCirculerLoader from '../../../components/ButtonCirculerLoader';
import { useMainSlice } from '../../../pages/main/actions';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

export default function StripeCheckoutForm(props) {
  const _errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useMainSlice();
  const cartData = useSelector(selectCartData);
  const currency = useSelector(selectRestaurantCurrency);
  // const tip = useSelector(selectTipAmount);
  const stripeAccountId = useSelector(selectStripeAccountId);
  const getCheckoutSession = useCallback(() => {
    dispatch(
      actions.getStripeCheckoutSession({
        accountId: stripeAccountId,
        currency,
        data: cartData,
        url: window.location.href,
      })
    );
  }, []);
  useEffect(() => {
    getCheckoutSession();
  }, []);
  const stripePaymentIntentSecret = useSelector(
    selectStripePaymentIntentSecret
  );

  const options = {
    clientSecret: stripePaymentIntentSecret,
    appearance: {},
  };

  return (
    <div className="stripe-checkout-form">
      {stripePaymentIntentSecret ? (
        <Elements stripe={window.stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      ) : (
        <>
          {_errorMessage ? (
            <p className="stripe-checkout error">{_errorMessage}</p>
          ) : (
            <p className="stripe-checkout loader">{t('cart.initializing')}</p>
          )}
        </>
      )}
    </div>
  );
}
export const CheckoutForm = () => {
  const { table } = queryString.parse(window.location.search);
  const _errorMessage = useSelector(selectErrorMessage);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [elementLoading, setElementLoading] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          window.location.origin +
          window.location.pathname +
          `?cart=true${table ? '&table=' + table : ''}`,
      },
    });

    if (error) {
      setLoading(false);
      setErrorMessage(error.message);
    } else {
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => setElementLoading(false)} />
      {!elementLoading ? (
        <button disabled={!stripe || loading} className="main-button">
          {t('cart.confirm')} {loading && <ButtonCirculerLoader />}
        </button>
      ) : (
        <>{_errorMessage ? null : <p>{t('cart.initializing')}</p>}</>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
