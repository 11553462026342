import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import af from './af/translation.json';
import ar from './ar/translation.json';
import da from './da/translation.json';
import de from './de/translation.json';
import nl from './nl/translation.json';
import el from './el/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fi from './fi/translation.json';
import fr from './fr/translation.json';
import hi from './hi/translation.json';
import id from './id/translation.json';
import it from './it/translation.json';
import ja from './ja/translation.json';
// import ko from './ko/translation.json';
import ms from './ms/translation.json';
import nb from './nb/translation.json';
import pl from './pl/translation.json';
import pt from './pt/translation.json';
import ru from './ru/translation.json';
import sv from './sv/translation.json';
import th from './th/translation.json';
import tr from './tr/translation.json';
import vi from './vi/translation.json';
import zh_Hans from './zh-Hans/translation.json';
import zh_Hant from './zh-Hant/translation.json';
import he from './he/translation.json';
import ro from './ro/translation.json';

export const translationsJson = {
  en: {
    translation: en,
  },
  af: {
    translation: af,
  },
  hi: {
    translation: hi,
  },
  fr: {
    translation: fr,
  },
  ar: {
    translation: ar,
  },
  da: {
    translation: da,
  },
  de: {
    translation: de,
  },
  nl: {
    translation: nl,
  },
  el: {
    translation: el,
  },
  es: {
    translation: es,
  },
  id: {
    translation: id,
  },
  pt: {
    translation: pt,
  },
  fi: {
    translation: fi,
  },
  it: {
    translation: it,
  },
  ja: {
    translation: ja,
  },
  // ko: {
  //   translation: ko,
  // },
  ms: {
    translation: ms,
  },
  nb: {
    translation: nb,
  },
  pl: {
    translation: pl,
  },
  ru: {
    translation: ru,
  },
  sv: {
    translation: sv,
  },
  th: {
    translation: th,
  },
  tr: {
    translation: tr,
  },
  vi: {
    translation: vi,
  },
  zh_Hans: {
    translation: zh_Hans,
  },
  zh_Hant: {
    translation: zh_Hant,
  },
  he: {
    translation: he,
  },
  ro: {
    translation: ro,
  },
};

// Create the 'translations' object to provide full intellisense support for the static json files.

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
