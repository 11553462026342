export const SELECTED_TAB = {
  DESCRIPTION: 'DESCRIPTION',
  INGREDIENTS: 'INGREDIENTS',
  MODIFIERS: 'MODIFIERS',
};
export const CART_ITEM_PROPERTIES = {
  FOOD: 'food',
  MODIFIERS: 'modifiers',
  PROMOTIONS: 'promotions',
  TAXES: 'taxes',
  DISCOUNT: 'discount',
  TAX: 'tax',
  TOTAL: 'total',
};
export const TAX_TYPES = {
  include_to_price: 'INCLUDED_TO_PRICE',
  not_include_to_price: 'NOT_INCLUDE_TO_PRICE',
};
export const DINE_IN_MODES = {
  DINE_IN: 'dine_in',
  TAKE_OUT: 'take_out',
};
export const PROMOTION_TYPES = {
  CART: 'CART',
  ITEM: 'ITEM',
};
export const PROMOTION_DISCOUNT_TYPES = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT',
};
