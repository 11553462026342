import React, { useRef } from "react";
import { NavHashLink } from "react-router-hash-link";
import "../styles/horizontalCategoryBar.scss";
import { selectFoodsAndCategories } from "../selectors";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export default function HorizontalCategoryBar() {
  const location = useLocation();
  const foodsAndCategories = useSelector(selectFoodsAndCategories);
  const carouselRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <div
      className="horizintal-category-bar-wrapper"
      id="horizintal-category-bar"
    >
      {foodsAndCategories?.length > 0 && (
        <div className="contents max-width-wrapper">
          <FaChevronLeft
            className="icon"
            onClick={() => carouselRef.current.previous()}
          />
          {foodsAndCategories?.length > 0 ? (
            <Carousel
              responsive={responsive}
              arrows={false}
              swipeable
              id="multi-carousel-outer"
              // infinite
              ref={(el) => (carouselRef.current = el)}
            >
              {foodsAndCategories?.map((item, index) => (
                <NavHashLink
                  activeClassName="selected"
                  scroll={(el) => scrollWithOffset(el)}
                  key={index}
                  to={`${location.pathname}${location.search}#category-${index}`}
                >
                  <p>
                    {item.category[0]?.name[i18next.language || "en"] ||
                      item.category[0]?.name["en"]}
                  </p>
                </NavHashLink>
              ))}
            </Carousel>
          ) : null}
          <FaChevronRight
            className="icon"
            onClick={() => carouselRef.current.next()}
          />
        </div>
      )}
    </div>
  );
}
