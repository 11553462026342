import React from 'react';
import { BiCheck } from 'react-icons/bi';
import '../styles/orderSuccess.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

export default function OrderSuccess({ closeModal }) {
  const history = useHistory();
  const { table } = queryString.parse(window.location.search);
  const { t } = useTranslation();
  const handleDone = () => {
    let url = `${window.location.pathname}`;
    if (table) {
      url = url + '?table=' + table;
    }
    history.push(url);
    closeModal();
  };
  return (
    <div className="OrderSuccess">
      <BiCheck className="checkIcon" />
      <h4>{t('cart.success')}</h4>
      <p>{t('cart.order_place_success')}</p>
      <button className="main-button" onClick={() => handleDone()}>
        {t('cart.done')}
      </button>
    </div>
  );
}
