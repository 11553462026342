/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import "../styles/cartModal.scss";
import { MdOutlineClose } from "react-icons/md";
import CartOrders from "./CartOrders";
import configs from "../../../configs";
import {
  selectOrderSavingSucceeded,
  selectStripePaymentIntentStatus,
  selectRestaurantUrl,
  selectStripeConenctedAccountId,
} from "../../../pages/main/selectors";
import CartPaymentType from "./CartPaymentType";
// import CartPayment from './CartPayment';
import { MODAL_STATUS } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import OrderSuccess from "./OrderSuccess";
import StripeCheckoutForm from "./StripeCheckoutForm";
import queryString from "query-string";
import { useMainSlice } from "../../../pages/main/actions";

export default function CartModal(props) {
  const orderSavingSucceeded = useSelector(selectOrderSavingSucceeded);
  const restaurantUrl = useSelector(selectRestaurantUrl);
  const accountId = useSelector(selectStripeConenctedAccountId);
  const [modalStatus, setModalStatus] = useState(MODAL_STATUS.PLACE_ORDER);
  const stripePaymentIntentStatus = useSelector(
    selectStripePaymentIntentStatus
  );
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { payment_intent } = queryString.parse(window.location.search);
  const location = window.location;

  useEffect(() => {
    if (orderSavingSucceeded) {
      setModalStatus(MODAL_STATUS.SUCCEEDED);
      dispatch(actions.reSetCartData({}));
    }
  }, [orderSavingSucceeded]);

  useEffect(() => {
    if (props.isOpen && !payment_intent) {
      setModalStatus(MODAL_STATUS.PLACE_ORDER);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (payment_intent && accountId) {
      const cartData = localStorage.getItem(`cart-${configs.APP_DOMAIN}`);
      if (cartData) {
        dispatch(
          actions.getPaymentIntentDetails({
            intentId: payment_intent,
            accountId: accountId,
          })
        );
      }
    }
  }, [payment_intent, accountId]);

  useEffect(() => {
    if (stripePaymentIntentStatus) {
      setModalStatus(MODAL_STATUS.SELECT_PAYMENT_TYPE);
    }
  }, [stripePaymentIntentStatus]);

  const ReturnModalContent = useCallback(() => {
    switch (modalStatus) {
      case MODAL_STATUS.PLACE_ORDER:
        return (
          <CartOrders
            setModalStatus={setModalStatus}
            closeModal={() => props.toggle()}
          />
        );
      case MODAL_STATUS.SELECT_PAYMENT_TYPE:
        return <CartPaymentType setModalStatus={setModalStatus} />;
      // case MODAL_STATUS.PAY:
      //   return <CartPayment setModalStatus={setModalStatus} />;
      case MODAL_STATUS.CHECKOUT:
        return <StripeCheckoutForm setModalStatus={setModalStatus} />;
      case MODAL_STATUS.SUCCEEDED:
        return <OrderSuccess closeModal={() => props.toggle()} />;
      default:
        return null;
    }
  }, [modalStatus]);

  return (
    <Modal
      isOpen={
        props.isOpen &&
        location.pathname !== `/${restaurantUrl}/login` &&
        location.pathname !== `/${restaurantUrl}/register`
      }
      toggle={props.toggle}
      className={`${
        modalStatus === MODAL_STATUS.PAY ? "pay" : ""
      } cart-modal-wrapper`}
    >
      {!payment_intent && (
        <MdOutlineClose className="closeIcon" onClick={props.toggle} />
      )}
      <ReturnModalContent />
    </Modal>
  );
}
