import React, { useState } from "react";
import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { requestHotAction } from "../../socket/socketCtrl";
import { MdOutlineClose } from "react-icons/md";
import HotActionItem from "./components/HotActionItem";

import {
  selectHotActions,
  selectRestaurantUrl,
  selectRestaurantId,
  selectTable,
} from "../../pages/main/selectors";
import { selectSelectedStore } from "../../pages/menuPage/selectors";

import "./styles.scss";

function HotActionsModal(props) {
  const { isOpen, onClose } = props;

  const [activeHotAction, setActiveHotAction] = useState(null);
  const [requestSent, setRequestSent] = useState(false);

  const { t } = useTranslation();

  const hotActions = useSelector(selectHotActions);
  const restaurantUrl = useSelector(selectRestaurantUrl);
  const restaurantId = useSelector(selectRestaurantId);
  const store = useSelector(selectSelectedStore);
  const selectedTable = useSelector(selectTable);

  const { name } = selectedTable;

  const handleSendRequest = (hotAction) => {
    setActiveHotAction(null);
    setRequestSent(true);
    requestHotAction({
      room: restaurantUrl,
      hotActionId: hotAction?._id,
      storeId: store._id,
      table: name,
      restaurantId,
    });
    setTimeout(() => onClose(), 2500);
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="hot-actions-container">
        <MdOutlineClose className="close-hot-actions" onClick={onClose} />
        <h3>{t("hot_actions.modal_title")}</h3>

        <div className="hot-action-items-container">
          {hotActions?.length ? (
            hotActions?.map((hotAction, index) => (
              <HotActionItem
                key={index}
                activeHotAction={activeHotAction}
                requestSent={requestSent}
                data={hotAction}
                onItemClick={(id) => {
                  if (requestSent) setRequestSent(false);
                  setActiveHotAction(id);
                }}
                onSendRequest={handleSendRequest}
              />
            ))
          ) : (
            <p>{t("hot_actions.no_hot_actions_to_display")}</p>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default HotActionsModal;
