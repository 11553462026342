export const calcSubTotal = ({ cartData }) => {
  try {
    let _total = 0;
    cartData.map((item) => {
      _total = _total + item.total;
      return null;
    });
    return parseFloat(_total.toFixed(2));
  } catch (error) {
    console.log(error);
  }
};
export const reOrderThePurchase = ({ cartData }) => {
  try {
    let orderData = [];

    cartData.map((item) => {
      let singleOrder = {};
      Object.keys(item).map((key, __index) => {
        if (key !== "total" && key !== "promotions") {
          if (__index === 1) {
            singleOrder = {
              ...singleOrder,
              food: {
                id: key,
                count: item[key].count,
                name: item[key].name,
                price: item[key].price,
                instructions: item[key].instructions,
              },
            };
          } else {
            singleOrder = {
              ...singleOrder,
              modifiers: [
                ...(singleOrder.modifiers ? singleOrder.modifiers : []),
                {
                  id: key,
                  count: item[key].count,
                  name: item[key].name,
                  price: item[key].price,
                },
              ],
            };
          }
        }
        return null;
      });
      singleOrder.total = item.total;
      orderData.push(singleOrder);
      return null;
    });
    return orderData;
  } catch (error) {
    console.log(error);
  }
};
export const generateDescription = ({ cartData, lan, currency = "USD" }) => {
  try {
    console.log("generating went", cartData);
    let temp_description = [
      {
        name: "Order",
        unit_amount: {
          value: parseFloat(Math.round(cartData.total * 100) / 100),
          currency_code: currency,
        },
        quantity: 1,
      },
    ];

    // cartData.items.forEach((item) => {
    //   temp_description.push({
    //     name: item.food.name[lan],
    //     unit_amount: {
    //       value: parseFloat(Math.round(item.food.total * 100) / 100),
    //       currency_code: currency,
    //     },
    //     quantity: item.food.count,
    //   });
    //   if (item.modifiers && item.modifiers.items.length > 0) {
    //     item.modifiers.items.forEach((_modifier) => {
    //       temp_description.push({
    //         name: _modifier.name[lan],
    //         unit_amount: {
    //           value: parseFloat(Math.round(_modifier.price * 100) / 100),
    //           currency_code: currency,
    //         },
    //         quantity: _modifier.count,
    //       });
    //     });
    //   }
    // });
    // if (cartData.tip > 0) {
    //   temp_description.push({
    //     name: "Tip",
    //     unit_amount: {
    //       value: cartData.tip,
    //       currency_code: currency,
    //     },
    //     quantity: 1,
    //   });
    // }
    // if (cartData.tax_not_included > 0) {
    //   temp_description.push({
    //     name: "Tax",
    //     unit_amount: {
    //       value: cartData.tax_not_included,
    //       currency_code: currency,
    //     },
    //     quantity: 1,
    //   });
    // }
    console.log("desciption", temp_description);
    return temp_description;
  } catch (error) {
    console.log(error);
    window.alert(error);
  }
};
