import {
  BsEmojiSmile,
  BsEmojiExpressionless,
  BsEmojiFrown,
  BsEmojiHeartEyes,
} from "react-icons/bs";
import { Controller } from "react-hook-form";
import IconGroup from "../../../components/IconGroup";
import { QUESTION_TYPES } from "../../main/constants";

const icons = [
  {
    name: "Heart",
    icon: <BsEmojiHeartEyes size={40} name={"Stmjj"} />,
  },
  {
    name: "Smile",
    icon: <BsEmojiSmile size={40} />,
  },
  {
    name: "Frown",
    icon: <BsEmojiFrown size={40} />,
  },
  {
    name: "Expressionless",
    icon: <BsEmojiExpressionless size={40} />,
  },
];
const SmileQuesion = ({ question,name,control,required }) => {
  return (
    <div className="qs">
      <Controller
        control={control}
        rules={{ required: required }}
        name={name}
        render={({ field: { onChange } }) => (
          <IconGroup icons={icons} onChange={(value)=>onChange({type:QUESTION_TYPES.SMILEY,answer:value,question:question})} />
        )}
      />
    </div>
  );
};

export default SmileQuesion;
