import React from 'react';
import './styles.scss';
import errorTriangle from '../../images/error-triangle.svg';

export default function RestrictedPage() {
  return (
    <div className="PERSONAL_NOT_ALLOWED_PAGE--">
      <div className="content">
        <div className="innerBox">
          <img src={errorTriangle} alt="" />
          <h1>This page is temporarily inactive.</h1>
          <div className="descr">
            <p>Please contact the restaurant admisnistration</p>
          </div>
          {/* <div className="helpline">
            Have a question?&nbsp;
            <a href="/">Let us know how we can help you.</a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
