/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal } from "reactstrap";
import "./styles.scss";
import { RiStore2Line } from "react-icons/ri";
import { selectOpenStoreSelector } from "../../pages/homePage/selectors";
import {
  selectStores,
  selectCartItemCount,
  selectOrdersInProgressCount,
} from "../../pages/main/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useHomeSlice } from "../../pages/homePage/actions";
import { useHistory, useParams } from "react-router-dom";
import i18next from "i18next";
import { mainActions } from "../../pages/main/actions";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import configs from "../../configs";

export default function StoreSelector() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useHomeSlice();
  const { t } = useTranslation();
  const isStoreSelectorOpen = useSelector(selectOpenStoreSelector);
  const stores = useSelector(selectStores);
  const { restaurantUrl } = useParams();
  const cartItems = useSelector(selectCartItemCount);
  const ordersCount = useSelector(selectOrdersInProgressCount);

  useEffect(() => {
    if (isStoreSelectorOpen) {
      const _limited_store = localStorage.getItem(
        `store_limit_id-${configs.APP_DOMAIN}`
      );
      if (_limited_store) {
        routeTomenu(_limited_store);
      } else {
        const store = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
        if (store && cartItems > 0) {
          routeTomenu(store);
        } else if (store && ordersCount > 0) {
          routeTomenu(store);
        } else if (stores && stores.length === 1) {
          routeTomenu(stores[0]?._id);
        }
      }
    }
  }, [isStoreSelectorOpen, cartItems]);

  const StoreBox = ({ data }) => (
    <div
      className={`store-box `}
      onClick={() => {
        routeTomenu(data._id);
      }}
    >
      <RiStore2Line className="icon" />
      <h6>{data.name[i18next.language || "en"]}</h6>
      <p>{data.address?.[i18next.language || "en"]}</p>
    </div>
  );

  const routeTomenu = (store) => {
    localStorage.setItem(`store-${configs.APP_DOMAIN}`, store);
    history.push(`/${restaurantUrl}/store/${store}`);
    dispatch(mainActions.setSelectedStoreId(store));
    dispatch(actions.toggleStoreSelector());
  };
  return (
    <>
      {isStoreSelectorOpen && (
        <Modal
          isOpen={isStoreSelectorOpen}
          toggle={() => dispatch(actions.toggleStoreSelector())}
          className="store-selector-wrapper"
        >
          <div className="inner-wrapper">
            <div className="close">
              <span onClick={() => dispatch(actions.toggleStoreSelector())}>
                <IoMdClose size={25} />
              </span>
            </div>
            <h4>{t("store_selector.select_your_store")}</h4>
            <div className="shops">
              {stores.map((_store, index) => (
                <StoreBox key={index} data={_store} />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
