import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { selectRestaurantCurrency } from "../../pages/main/selectors";
import { useTranslation } from "react-i18next";
import { BsHandThumbsUp } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";

export default function DiscountCard({ data }) {
  const { t } = useTranslation();
  const currency = useSelector(selectRestaurantCurrency);

  return (
    <div className="discountCard">
      <div className="right">
        <div className="food-details">
          <div>
            <div className="discount">
              <div className="name">{data?.name["en"]}</div>
              <div className="value">
                <span>
                  <BsHandThumbsUp />
                </span>
                <span>
                  {t("discount_card.you_saved", {
                    discount: data?.discount,
                  })}
                </span>
              </div>
            </div>
          </div>
          <h6 className="price">{`- ${currency} ${data?.discount}`}</h6>
        </div>
        {data.hasOwnProperty("minOrderAmount") ? (
          <div className="conditions-box">
            <div className="heading">
              {t("discount_card.promo_deal_conditions")}
            </div>
            <div className="condition">
              <span className="icon">
                <GiCheckMark />
              </span>
              <span>
                {t("discount_card.total_price", {
                  currency: currency,
                  minOrderAmount: data?.minOrderAmount,
                })}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
