import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import ButtonGroup from "../../../components/ButtonGroup";
import { QUESTION_TYPES } from "../../main/constants";

const YesNoQuesion = ({ question, name, control, required }) => {
  const { t } = useTranslation();

  // const buttons = ["Yes", "No"];
  const buttons = [t("homePage.feedback.yes"), t("homePage.feedback.no")];

  return (
    <div className="qs">
      <Controller
        control={control}
        rules={{ required: required }}
        name={name}
        render={({ field: { onChange } }) => (
          <ButtonGroup
            buttons={buttons}
            onChange={(value) =>
              onChange({
                type: QUESTION_TYPES.YES_NO,
                answer: value,
                question: question,
              })
            }
          />
        )}
      />
    </div>
  );
};

export default YesNoQuesion;
