import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LazyLoad from "react-lazyload";
import configs from "../../../../configs";

import ButtonCirculerLoader from "../../../ButtonCirculerLoader";

import "./styles.scss";
import SendButton from "../SendButton";

function HotActionItem(props) {
  const { data, activeHotAction, onItemClick, onSendRequest } = props;
  const { t } = useTranslation();

  return (
    <LazyLoad
      style={{ width: "100%" }}
      placeholder={<ButtonCirculerLoader />}
      height={132}
      offset={[-200, 0]}
    >
      <div
        className={`hot-action-item-container ${
          activeHotAction === data?._id ? "active" : ""
        }`}
        onClick={() => {
          onItemClick(data?._id);
        }}
      >
        <div className="hot-action-image-container">
          <img
            src={configs.RESOURCES_BUCKET_URL + "/" + data.imageUrl}
            alt=""
          />
        </div>
        <div className="hot-action-info-container">
          <div className="hot-action-text-container">
            <h6 className="hot-action-name">
              {data?.header?.[i18next.language || "en"] || data.header["en"]}
            </h6>
            <p className="hot-action-message">
              {data?.message?.[i18next.language || "en"] || data.message["en"]}
            </p>
          </div>
          <div className="send-request-container">
            {activeHotAction === data?._id && (
              <SendButton
                name={t("hot_actions.send_request")}
                onClick={() => onSendRequest(data)}
              />
            )}
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}

export default HotActionItem;
