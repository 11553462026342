import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './actions';

const selectSlice = (state) => state?.main || initialState;

export const selectMain = createSelector([selectSlice], (state) => state);

export const selectIsLoading = createSelector(
  [selectSlice],
  (state) => state.loading
);

export const selectRestaurantData = createSelector(
  [selectSlice],
  (state) => state.restaurantData
);
export const selectRestaurantId = createSelector(
  [selectSlice],
  (state) => state.restaurantData?._id
);
export const selectRestaurantUrl = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.restaurantUrl
);
export const selectRestaurantLanguages = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.languages || ['en']
);
export const selectHomeHeroSection = createSelector(
  [selectSlice],
  (state) => state.restaurantData.heroSection
);
export const selectRestaurantCoverImg = createSelector(
  [selectSlice],
  (state) => state.restaurantData.coverImg
);
export const selectRestaurantName = createSelector(
  [selectSlice],
  (state) => state.restaurantData.name
);
export const selectNewsletterInfo = createSelector(
  [selectSlice],
  (state) => state?.restaurantData?.newsletter
);
export const selectWhiteLabelEnabled = createSelector(
  [selectSlice],
  (state) => state.wheiteLabelEnabled
);
export const selectHomeAboutSection = createSelector(
  [selectSlice],
  (state) => state.restaurantData.about
);
export const selectHomeFeaturedFoodSection = createSelector(
  [selectSlice],
  (state) => state.restaurantData.bestMenu
);
export const selectFirstStoreId = createSelector(
  [selectSlice],
  (state) => state.restaurantData.storeIds?.[0]?._id
);
export const selectPromoSection = createSelector(
  [selectSlice],
  (state) => state.restaurantData.promo.promos
);
export const selectWhyChooseUsSection = createSelector(
  [selectSlice],
  (state) => state.restaurantData.whyChooseUs
);
export const selectContactDetails = createSelector([selectSlice], (state) => ({
  phoneNumber: state.restaurantData.phoneNumber,
  address: state.restaurantData.address,
  email: state.restaurantData.email,
}));
export const selectStores = createSelector(
  [selectSlice],
  (state) => state.restaurantData.storeIds
);
export const selectErrorMessage = createSelector(
  [selectSlice],
  (state) => state.errorMessage
);
export const selectCartItemCount = createSelector(
  [selectSlice],
  (state) => state.cartItemCount
);
export const selectCartData = createSelector(
  [selectSlice],
  (state) => state.cartData
);
export const selectCurrentUserId = createSelector(
  [selectSlice],
  (state) => state.currentUser?._id
);
export const selectCurrentUserStoreId = createSelector(
  [selectSlice],
  (state) => state.currentUser?.storeId
);
export const selectIsAuthenicated = createSelector(
  [selectSlice],
  (state) => state.authenticated
);
export const selectAuthLoading = createSelector(
  [selectSlice],
  (state) => state.loginLoading
);
export const selectPaymentmethod = createSelector(
  [selectSlice],
  (state) => state.paymentMethord
);
export const selectRestaurantCurrency = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.currency || 'USD'
);
export const selectOrderSavingInProgress = createSelector(
  [selectSlice],
  (state) => state.orderSavingInProgress
);
export const selectOrderSavingSucceeded = createSelector(
  [selectSlice],
  (state) => state.orderSavingSucceeded
);
export const selectOrdersInProgressCount = createSelector(
  [selectSlice],
  (state) => state.ordersInProgress.length
);
export const selectOrdersInProgress = createSelector(
  [selectSlice],
  (state) => state.ordersInProgress
);
export const selectSelectedStoreId = createSelector(
  [selectSlice],
  (state) => state.selectedStoreId
);
export const selectPaymentOptions = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.paymentIntegrations || {}
);
export const selectPaypalClientId = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.paymentIntegrations.paypal.clientId
);
export const selectStripeAccountId = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.paymentIntegrations.stripe.accountId
);
export const selectCurrency = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.currency || 'USD'
);
export const selectResetPasswordLinkSent = createSelector(
  [selectSlice],
  (state) => state.resetPasswordLinkSent
);
export const selectResetPasswordSecceeded = createSelector(
  [selectSlice],
  (state) => state.resetPasswordSucceeded
);
export const selectCurrentPromotions = createSelector(
  [selectSlice],
  (state) => state.promotions
);
export const selectSubscriptionStatus = createSelector(
  [selectSlice],
  (state) => state.subscription === 'active'
);
export const selectTable = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.table
);
export const selectStripeConenctedAccountId = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.paymentIntegrations?.stripe?.accountId
);
export const selectSubscriptionPlan = createSelector(
  [selectSlice],
  (state) => state.subscription.planId
);

//survey page
export const selectRestaurantSurveyPage = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.surveyId
);
export const selectStripePaymentIntentSecret = createSelector(
  [selectSlice],
  (state) => state.stripePaymentIntentClientSecret
);
export const selectStripeCheckoutInProgress = createSelector(
  [selectSlice],
  (state) => state.stripeCheckoutInProgress
);
export const selectStripePaymentIntentStatus = createSelector(
  [selectSlice],
  (state) => state.paymentIntentDetails?.status
);
export const selectStripePaymentChargeId = createSelector(
  [selectSlice],
  (state) => state.paymentIntentDetails?.charges.data[0].id
);
export const selectFonts = createSelector(
  [selectSlice],
  (state) => state.fonts
);

export const selectSelectedInprogressOrder = createSelector(
  [selectSlice],
  (state) => state.selectedInprogressOrder
);

export const selectOrderSetting = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.order.customer
);

export const selectOrderEditingSucceeded = createSelector(
  [selectSlice],
  (state) => state.orderEditingSucceeded
);
export const selectOrderEditingInProgress = createSelector(
  [selectSlice],
  (state) => state.orderEditingInProgress
);

export const selectHotActions = createSelector(
  [selectSlice],
  (state) => state.hotActions.data
);
export const selectHotActionsLoading = createSelector(
  [selectSlice],
  (state) => state.hotActions.loading
);

export const selectLocationDetails = createSelector(
  [selectSlice],
  (state) => state.locationDetails
);


export const selectIsDefaultImageEnable = createSelector(
  [selectSlice],
  (state) => state.restaurantData?.enableDefaultFoodImage
);
