import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectOrderSavingInProgress,
  selectPaymentOptions,
} from "../../../pages/main/selectors";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "../styles/CustomOptions.scss";
import ButtonCirculerLoader from "../../ButtonCirculerLoader";

export default function CustomOptionsList(props) {
  const pameymentOptions = useSelector(selectPaymentOptions);
  const { t } = useTranslation();
  const customOptions = pameymentOptions?.customOptions?.options || [];
  const orderSavingInProgress = useSelector(selectOrderSavingInProgress);
  const [selectedOptionIndex, setOption] = useState(null);
  return (
    <div className="--CUSTOMOPTIONS">
      {customOptions.map((option, index) =>
        option?.enable ? (
          <div
            className="--CUSTOMOPTIONS-OPTION"
            key={`custom-option-${index}`}
            onClick={() => setOption(index)}
          >
            <input
              type="radio"
              checked={selectedOptionIndex === index}
              readOnly
            />
            <h6>
              {option.name?.[i18next.language || "en"] || option.name?.["en"]}
            </h6>
          </div>
        ) : null
      )}
      <div className="button-wrapper">
        <button
          className="cancel-button"
          onClick={() => props.onBack()}
          disabled={orderSavingInProgress}
        >
          {t("cart.back")}
        </button>
        <button
          className="main-button"
          disabled={selectedOptionIndex === null || orderSavingInProgress}
          onClick={() =>
            props.onSuccess(customOptions[selectedOptionIndex]?.name?.["en"])
          }
        >
          {t("cart.confirm")}{" "}
          {orderSavingInProgress && <ButtonCirculerLoader />}
        </button>
      </div>
    </div>
  );
}
