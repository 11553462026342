import { createSlice } from '../../Redux/toolkit';
import { useInjectReducer, useInjectSaga } from '../../Redux/redux-injectors';
import homeSaga from './saga';
// import moment from 'moment';

export const initialState = {
  loading: false,
  featuredFoods: null,
  errorMessage: null,
  openStoreSelector: false,
  savingNewsletter: false,
  newsletterSaved: false,
};

export const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    getFeaturedFoods(state, action) {
      state.loading = true;
    },
    getFeaturedFoodsSucceeded(state, action) {
      state.loading = false;
      state.featuredFoods = action.payload;
    },
    getFeaturedFoodsFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    toggleStoreSelector(state) {
      state.openStoreSelector = !state.openStoreSelector;
    },
    saveNewsletter(state, action) {
      state.savingNewsletter = true;
    },
    saveNewsletterSucceeded(state, action) {
      state.savingNewsletter = false;
      state.newsletterSaved = true;
    },
    saveNewsletterFailed(state, action) {
      state.savingNewsletter = false;
      state.newsletterSaved = true;
      state.errorMessage = action.payload;
    },
    clearNewsletterSavedStatus(state) {
      state.newsletterSaved = false;
    },
  },
});

export const { actions: homeActions } = slice;

export const useHomeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: homeSaga });
  return { actions: slice.actions };
};
