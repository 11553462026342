export const API = {
  GET_STORE_DATA: {
    path: '/customer-app/stores/:storeId',
    method: 'GET',
  },
  GET_FOODS_AND_CATEGORIES: {
    path: '/customer-app/foods/:storeId/:menuId/:option/:currentDateTime',
    method: 'GET',
  },
  GET_FOODS_AND_CATEGORIES_BY_SEARCH: {
    path: '/customer-app/foods',
    method: 'POST',
  },
  GET_MODIFERS: {
    path: '/customer-app/modifiers',
    method: 'POST',
  },
  GET_TAXES: {
    path: '/customer-app/taxes',
    method: 'POST',
  },
  GET_RECOMENDED_FOODS: {
    path: '/customer-app/foods/recomended',
    method: 'POST',
  },
  GET_PROMOTIONS_BY_FOOD: {
    path: '/customer-app/promotions/food/:id/:currentTime',
    method: 'GET',
  },
  VALIDATE_STORE: {
    path: '/customer-app/stores/validate',
    method: 'POST',
  },
};
