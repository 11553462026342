import { createSlice } from '../../Redux/toolkit';
import { useInjectReducer, useInjectSaga } from '../../Redux/redux-injectors';
import answerSaga from './saga';

export const initialState = {
  loading: false,
  answerSaved: false,
  errorMessage: null,
};

export const slice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    saveSurveyAnswer(state, action) {
      state.loading = true;
    },
    saveSurveyAnswerSucceeded(state, action) {
      state.loading = false;
      state.answerSaved = true;
    },
    saveSurveyAnswerFailed(state, action) {
      state.loading = false;
      state.answerSaved = true;
      state.errorMessage = action.payload;
    }
  },
});

export const { actions: surveyAnserActions } = slice;

export const useAnswerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: answerSaga });
  return { actions: slice.actions };
};
