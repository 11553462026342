import React from 'react';
import { Modal } from 'reactstrap';
import { ImExit } from 'react-icons/im';
import configs from '../../configs';
import { useDispatch } from 'react-redux';
import './styles.scss';
import { useMainSlice } from '../../pages/main/actions';
import { useTranslation } from 'react-i18next';

export default function SignOutModal({ isOpen, toggle }) {
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { t } = useTranslation();
  const signOut = () => {
    localStorage.removeItem(`token-${configs.APP_DOMAIN}`);
    dispatch(actions.clearCurrentUser());
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      className="signout-container"
      toggle={() => toggle()}
    >
      <div className="inner-wrapper">
        <ImExit className="icon" />
        <h4>{t('sign_out.sign_out')}</h4>
        <p>{t('sign_out.are_u_sure')}</p>
      </div>
      <div className="button-container">
        <button className="main-light-button" onClick={() => toggle()}>
          {t('sign_out.cancel')}
        </button>
        <button className="main-button" onClick={() => signOut()}>
          {t('sign_out.sign_out')}
        </button>
      </div>
    </Modal>
  );
}
