import React from 'react';
import './styles.scss';

export default function ButtonLoader() {
  return (
    <div className="button-loader">
      <div />
      <div />
      <div />
    </div>
  );
}
