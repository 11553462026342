/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FoodDescriptionModal from "../../components/FoodDescriptionModal";
import FoodsInCategory from "./components/FoodsInCategory";
import MenuHeroSection from "./components/MenuHeroSection";
import configs from "../../configs";
import {
  selectFoodsAndCategories,
  selectIsLoading,
  selectSelectedFood,
  selectGetFoodsInProgress,
  selectStoreValidated,
  selectEnableOrdering,
  selectMenuId,
} from "./selectors";
import { selectCurrentPromotions, selectRestaurantId } from "../main/selectors";
import { useMenuSlice } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./styles.scss";
import Loading from "../../components/Loading";
import PromoSection from "../homePage/components/PromoSection";
import HorizontalCategoryBar from "./components/HorizontalCategoryBar";
import ButtonCirculerLoader from "../../components/ButtonCirculerLoader";
import queryString from "query-string";
import { mainActions } from "../../pages/main/actions";
import "react-toastify/dist/ReactToastify.css";

export default function MenuPage() {
  const { t } = useTranslation();

  const foodsAndCategories = useSelector(selectFoodsAndCategories);
  const [openFoodDescriptionModl, setFoodDescriptionModlOpen] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const foodsLoading = useSelector(selectGetFoodsInProgress);
  const storeValidated = useSelector(selectStoreValidated);
  const promos = useSelector(selectCurrentPromotions);
  const restaurantId = useSelector(selectRestaurantId);
  const enableOrdering = useSelector(selectEnableOrdering);
  const menuId = useSelector(selectMenuId);

  const { table } = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { actions } = useMenuSlice();
  const { storeId } = useParams();
  const food = useSelector(selectSelectedFood);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (storeId && restaurantId) {
      let _store_id = storeId;
      if (table) {
        _store_id = _store_id + "?table=" + table;
      }
      localStorage.setItem(`store-${configs.APP_DOMAIN}`, `${_store_id}`);
      dispatch(actions.validateStore({ storeId, restaurantId }));
    }
  }, [storeId, restaurantId]);

  useEffect(() => {
    if (storeValidated && storeId) {
      const option = table ? "Dine-in" : "Takeaway";

      if (menuId) {
        dispatch(actions.getFoodsAndCategories({ storeId, menuId, option }));
      }

      dispatch(
        mainActions.getCurrentPromotions({
          restaurantId: restaurantId,
          storeId,
          orderType: option,
        }),
      );
    }
  }, [storeValidated, menuId]);

  useEffect(() => {
    if (food) {
      setFoodDescriptionModlOpen(false);
      setTimeout(() => {
        setFoodDescriptionModlOpen(true);
      }, 100);
    } else {
      setFoodDescriptionModlOpen(false);
    }
  }, [food]);

  const getOrderEnableStatus = () => {
    let status = false;
    if (table) {
      status = enableOrdering.dineIn;
    } else {
      status = enableOrdering.takeAway;
    }
    return status;
  };

  return (
    <>
      {!isLoading ? (
        <div className="menuPage-wrapper">
          {/* <MenuNavbar /> */}
          <MenuHeroSection />
          <HorizontalCategoryBar />
          {promos?.length > 0 && <PromoSection />}

          <div className="foods-category-wrapper max-width-wrapper">
            {/* <VerticalCategoryBar /> */}
            <div className="foods-wrapper">
              {foodsAndCategories?.map((item, index) => (
                <section key={index} id={`category-${index}`}>
                  <FoodsInCategory
                    key={index}
                    data={item}
                    enableOrdering={getOrderEnableStatus()}
                  />
                </section>
              ))}
              {foodsLoading ? (
                <div className="no-data-container">
                  {/* <p>Loading ...</p> */}
                  <ButtonCirculerLoader />
                </div>
              ) : (
                (!foodsAndCategories || foodsAndCategories?.length === 0) && (
                  <div className="no-data-container">
                    <p>{t("menuPage.contentSection.sorry_no_foods_found")}</p>
                  </div>
                )
              )}
            </div>
          </div>
          {openFoodDescriptionModl && (
            <FoodDescriptionModal enableOrdering={getOrderEnableStatus()} />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
