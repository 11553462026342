import i18next from 'i18next';

import './styles.scss';

const PriceVariantCard = ({
  defaultVariant,
  variant,
  currency,
  onPriceVariantSelect,
}) => {
  return (
    <label htmlFor={variant?._id} className={`price-variant-card-wrapper`}>
      <div className="action-btn">
        <input
          type="radio"
          id={variant?._id}
          name="priceVariant"
          value={variant?._id}
          checked={variant?._id === defaultVariant?._id}
          onChange={(e) => onPriceVariantSelect(e?.target?.value)}
        />
      </div>
      <p className="name">{variant?.name?.[i18next.language] || variant?.name?.['en']}</p>
      <div className="price">
        <p>
          {`${currency} ${Number.parseFloat(variant?.price).toFixed(2)}`}
        </p>
      </div>
    </label>
  );
};

export default PriceVariantCard;
