import React from 'react';
import '../styles/heroSection.scss';
import {
  selectHomeHeroSection,
  selectRestaurantCoverImg,
  selectRestaurantName,
} from '../../main/selectors';
import { useSelector, useDispatch } from 'react-redux';
import configs from '../../../configs';
import { useHomeSlice } from '../actions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Fade } from 'react-awesome-reveal';

export default function HeroSection() {
  const homeHeroSection = useSelector(selectHomeHeroSection);
  const coverImg = useSelector(selectRestaurantCoverImg);
  const restaurantname = useSelector(selectRestaurantName);
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const { t } = useTranslation();

  const openMenu = () => {
    dispatch(actions.toggleStoreSelector());
  };

  return (
    <div className="home-hero-section-wrapper">
      <div
        className="home-hero-banner-wrapper"
        // style={{
        //   backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
        //   url(${configs.RESOURCES_BUCKET_URL}/${coverImg})`,
        // }}
      >
        <img alt="" src={configs.RESOURCES_BUCKET_URL + '/' + coverImg} />
      </div>
      <div className="content">
        <Fade duration={2000} delay={200}>
          <div className="text-section">
            <h5>
              {t('homePage.heroSection.welcome_to')} {restaurantname}
            </h5>
            <h1>
              {homeHeroSection?.heading?.[i18next.language || 'en'] ||
                homeHeroSection?.heading?.['en']}
            </h1>
            {/* <hr /> */}
            <p>
              {homeHeroSection?.paragraph?.[i18next.language || 'en'] ||
                homeHeroSection?.paragraph?.['en']}
            </p>
            {homeHeroSection?.button_link.enable && (
              <button
                className="main-button"
                onClick={() => {
                  if (
                    homeHeroSection?.button_link.functionality_option ===
                    'DEFAULT'
                  ) {
                    openMenu();
                  } else {
                    window.location.replace(
                      homeHeroSection?.button_link.redirect_URL
                    );
                  }
                }}
              >
                {homeHeroSection?.button_link.functionality_option === 'DEFAULT'
                  ? t('homePage.heroSection.our_menu')
                  : homeHeroSection?.button_link?.text?.[
                      i18next.language || 'en'
                    ]}
              </button>
            )}
          </div>
        </Fade>
      </div>
    </div>
  );
}
