import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const ModifierGroupHeader = ({ name, type }) => {
  const { t } = useTranslation();
  return (
    <div className="modifire-header-container">
      <p className="title">{name}</p>
      {type === "required" && <p className="tag">{t('food_description.modifier.required')}</p>}
    </div>
  );
};

export default ModifierGroupHeader;
