import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from './constants';
import { homeActions as actions } from './actions';
import { request } from '../../utils/request';
import { selectRestaurantId } from '../main/selectors';
import configs from '../../configs';
function* getFeaturedFoods(action) {
  try {
    const restaurantId = yield select(selectRestaurantId);
    const _limited_store = localStorage.getItem(
      `store_limit_id-${configs.APP_DOMAIN}`
    );
    const result = yield call(
      request,
      API.GET_FEATURED_FOODS,
      {
        restaurantId: restaurantId,
        ...(_limited_store ? { storeId: _limited_store } : {}),
      },
      false
    ) || {};
    if (result.success) {
      yield put(actions.getFeaturedFoodsSucceeded(result.data));
    } else {
      yield put(actions.getFeaturedFoodsFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.getFeaturedFoodsFailed(e.message));
  }
}
function* saveNewsletter(action) {
  try {
    const result = yield call(
      request,
      API.SAVE_NEWSLETTER,
      action.payload,
      false
    ) || {};
    if (result.success) {
      yield put(actions.saveNewsletterSucceeded(result.data));
    } else {
      yield put(actions.saveNewsletterFailed(result.msg));
    }
  } catch (e) {
    yield put(actions.saveNewsletterFailed(e.message));
  }
}
function* homeSaga() {
  yield takeLatest(actions.getFeaturedFoods.type, getFeaturedFoods);
  yield takeLatest(actions.saveNewsletter.type, saveNewsletter);
}

export default homeSaga;
