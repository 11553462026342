/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Main from './pages/main';
// import configs from './configs';
// import { loadStripe } from '@stripe/stripe-js';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import NotFoundPage from './pages/notFoundPage';

// window.stripePromise = loadStripe(configs.STRIPE_PK);

function App() {
  const { i18n } = useTranslation();

  const handleLanguageChanged = useCallback(() => {
    if (i18next.language === 'ar' || i18next.language === 'he') {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:restaurantUrl" component={Main} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
