export const LANGUAGES = {
  // ar: 'Arabic',
  // du: 'Dutch',
  // en: 'English',
  // fr: 'French',
  // hn: 'Hindi',
  // id: 'Indonesian',
  // po: 'Portuguese',
  // sp: 'Spanish',

  af: 'Afrikaans',
  ar: 'عربي',
  da: 'dansk',
  de: 'Deutsch',
  el: 'Ελληνικά',
  en: 'English',
  es: 'Español',
  fi: 'Suomalainen',
  fr: 'Français',
  hi: 'हिन्दी',
  id: 'bahasa Indonesia',
  it: 'Italiano',
  ja: '日本',
  // ko: '한국인',
  ms: 'Melayu',
  nb: 'norsk',
  nl: 'Dutch',
  pl: 'Polski',
  pt: 'Português',
  ru: 'Русский',
  sv: 'svenska',
  th: 'ไทย',
  tr: 'Türk',
  vi: 'Tiếng Việt',
  zh_Hans: '简体中文',
  zh_Hant: '中国传统的',
  he: 'עִברִית',
  ro: 'Română',
};
