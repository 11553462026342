import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
// import { useSelector } from "react-redux";
// import { selectRestaurantCurrency } from "../../pages/main/selectors";
import { BsHandThumbsUp } from 'react-icons/bs';

export default function DiscountNotificationCard({ data }) {
  const { t } = useTranslation();
  // const currency = useSelector(selectRestaurantCurrency);

  return (
    <div className="discountNotificationCard">
      <div className="right">
        <div className="food-details">
          <div>
            <div className="discount">
              <div className="value">
                <span>
                  <BsHandThumbsUp />
                </span>
                <span>{t('discount_card.you_just_got_this_promo')}</span>
              </div>
              <div className="name">{data?.name['en']}</div>
            </div>
          </div>
          <div className="price">{t('discount_card.THANKS')}</div>
        </div>
      </div>
    </div>
  );
}
