import { Controller } from "react-hook-form";
import StarRating from "../../../components/StarRating";
import { QUESTION_TYPES } from "../../main/constants";

const StarRatingQuesion = ({ question,name,control, required }) => {
  return (
    <div className="qs">
      <Controller
        control={control}
        rules={{ required: required }}
        name={name}
        render={({ field: { onChange } }) => (
          <StarRating starCount={5} onChange={(value)=>onChange({type:QUESTION_TYPES.STAR_RATING,answer:value,question:question})} />
        )}
      />
    </div>
  );
};

export default StarRatingQuesion;
