import {
  configureStore
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createReducer } from './reducer';
import { createInjectorsEnhancer } from 'redux-injectors';
// import mainSaga from "../pages/Main/saga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const { run: runSaga } = sagaMiddleware;


const enhancers = [
  createInjectorsEnhancer({
    createReducer,
    runSaga,
  }),
];

  // Create the store with saga middleware
  // const middlewares = [sagaMiddleware];
// mount it on the Store
export const store = configureStore({
  reducer: createReducer(),
  middleware:(getDefaultMiddleware)=>getDefaultMiddleware().concat(sagaMiddleware),
  devTools:
      /* istanbul ignore next line */
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
    enhancers,
})

// then run the saga
// sagaMiddleware.run(mainSaga)
