import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import configs from "../../configs";
import { useMainSlice } from "../../pages/main/actions";
import i18next from "i18next";
import { selectRestaurantCurrency,selectIsDefaultImageEnable } from "../../pages/main/selectors";
// import { useTranslation } from "react-i18next";
import { MdOutlineClose } from "react-icons/md";
import { BsHandThumbsUp } from "react-icons/bs";
import sampleFoodImage from "../../images/sample-food.png";
import { FiImage } from "react-icons/fi";

export default function CartFoodCard({ index, data }) {
  // const { t } = useTranslation();
  const food = data["food"];
  const currency = useSelector(selectRestaurantCurrency);
  const isDefaultImageEnable = useSelector(selectIsDefaultImageEnable);
  const { actions } = useMainSlice();
  const dispatch = useDispatch();

  const onRemove = () => {
    dispatch(actions.removeCartItem(index));
  };

  return (
    <div className="foodCard">
      <MdOutlineClose className="close-icon" onClick={onRemove} />
      <div className="left">
      <>
          {food.imageUrls[0] ? (
            <img
              src={`${configs.RESOURCES_BUCKET_URL}/${food.imageUrls[0]}`}
              alt="Food"
            />
          ) : isDefaultImageEnable ? (
            <img src={sampleFoodImage} alt="Sample" />
          ) : (
            <></>
            // <FiImage size={70} color="#9e9a9a" />
          )}
        </>
      </div>
      <div className="right">
        <div className="food-details">
          <div>
            <h6 className="name">
              {food.count > 1 && `${food.count}x `}
              {food.name[i18next.language] || food.name["en"]}
              {food?.priceVariant?.name &&
                ` - ${food?.priceVariant?.name[i18next.language] || food?.priceVariant?.name["en"]}`}
            </h6>
            {data?.promotions?.map((promotion) => {
              return (
                <div className="discount">
                  <div className="name">
                    {food.count > 1 && `${food.count}x `}
                    {promotion.name["en"]}
                  </div>
                  <div className="value">
                    <span>
                      <BsHandThumbsUp />
                    </span>
                    <span>{`You saved ${promotion.discount}`}</span>
                  </div>
                </div>
              );
            })}
            <p></p>
          </div>
          <h6 className="price">{`${currency} ${Number.parseFloat(
            data.sub_total - data.discount,
          ).toFixed(2)}`}</h6>
        </div>
      </div>
    </div>
  );
}
