import React, { useState } from "react";
import { Modal } from "reactstrap";
import OrderDetailsCard from "./components/OrderDetailsCard";
import { MdOutlineClose } from "react-icons/md";
import { selectOrdersInProgress } from "../../pages/main/selectors";
import "./styles.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SingleOrderView from "./components/SingleOrderView";

export default function OrderDetailsModal({ isOpen, toggle }) {
  const orderCountinProgress = useSelector(selectOrdersInProgress);
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState(null);

  return (
    <Modal isOpen={isOpen} className="order-details-wrapper">
      <MdOutlineClose className="closeIcon" onClick={() => toggle()} />
      <div className="order-details-modal-content">
        {selectedOrder ? (
          <SingleOrderView
            data={selectedOrder}
            onBack={() => {
              setSelectedOrder(null);
            }}
          />
        ) : (
          <>
            <div className="header">
              <h4>{t("cart.active_orders")}</h4>
            </div>
            <div className="orders">
              {orderCountinProgress.map((item, index) => (
                <OrderDetailsCard
                  key={index}
                  data={item}
                  onClick={(data) => {
                    setSelectedOrder(data);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}
